/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback} from "react"
import {useTranslation} from "react-i18next"
import {useModel} from "hooks"
import {KlassappTableHOC} from "HOC"
import {KlassappTable} from "uiKit"
import {KlassappTableProps} from "types/common"
import moment from "moment"
import styles from "../../Documents.module.css"
import {UserDocument} from "types/user-documents"
import {Icon} from "components"

type Props = KlassappTableProps & {
    documents: UserDocument[]
    moveItem: (dragIndex: number, hoverIndex: number) => void
}

const PrintPrepareDocumentsTable: React.FC<Props> = ({
    columns,
    data,
    allFields,
    fields,
    orderField,
    isLoading,
    isLoadedTableFuncs,
    dispatch,
    dispatchFunc,
    onChangeFields,
    onDraggableColumn,
    onChangeAllFields,
    onUpdateRowData,
    onUpdateTableData,
    documents,
    moveItem
}) => {
    const {t} = useTranslation(["common"])
    const model = useModel()
    const userDateTimeFormat = model.getUserDateTimeFormat()
    const userDateFormat = model.getUserDateFormat()

    const getData = React.useCallback(async () => {
        dispatch({data: documents})
    }, [documents])

    const getFields = React.useCallback(() => {
        return ["Code", "Name", "Description", "Subunit", "Profile Type", "Date", "Approved"]
    }, [])

    const getColumns = React.useCallback(
        () => [
            {
                title: "Code",
                field: "code",
                style: {
                    minWidth: "70px",
                    maxWidth: "80px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }
            },
            {
                title: "Name",
                field: "name",
                style: {
                    minWidth: "90px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }
            },
            {
                title: "Description",
                field: "description",
                style: {
                    minWidth: "120px",
                    maxWidth: "130px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }
            },
            {
                title: "Subunit",
                field: "departmentSubunitName",
                style: {
                    minWidth: "100px",
                    maxWidth: "100px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                }
            },
            {
                title: "Profile Type",
                field: "profileType",
                style: {minWidth: "120px", maxWidth: "120px"}
            },
            {
                title: "Date",
                field: "createdAt",
                fieldType: "date",
                format: userDateFormat,
                style: {minWidth: "100px", maxWidth: "110px"}
            },
            {
                title: "Approved",
                field: "status",
                style: {
                    minWidth: "100px",
                    maxWidth: "110px",
                    whiteSpace: "nowrap",
                    textOverflow: "ellipsis",
                    overflow: "hidden"
                },
                render: (status, record) => {
                    const {statusUpdatedBy, statusUpdatedAt} = record
                    return (
                        <div className={styles.statusWrap}>
                            <div className={styles.status}>
                                {status === "approved" ? (
                                    <Icon icon="CHECKMARK_CIRCLE" color="#18A957" />
                                ) : status === "rejected" ? (
                                    <Icon icon="CROSS_CIRCLE_FILL" color="#DF1642" />
                                ) : (
                                    <Icon icon="CHECKMARK_CIRCLE_1" color="#E6E6E6" />
                                )}
                            </div>
                            {status !== "pending" && (
                                <div className={styles.statusInfo}>
                                    <p className={styles.statusInfo__text}>{statusUpdatedBy}</p>
                                    <p className={styles.statusInfo__text}>
                                        {moment(statusUpdatedAt).format(userDateTimeFormat)}
                                    </p>
                                </div>
                            )}
                        </div>
                    )
                }
            }
        ],
        [t, userDateTimeFormat]
    )

    React.useEffect(() => {
        dispatch({isClassComponent: false, isHideMenuActions: true})
        dispatchFunc([
            {key: "getPageTitle", func: () => ""},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns}
        ])
    }, [])

    React.useEffect(() => {
        if (isLoadedTableFuncs) {
            getData()
        }
    }, [isLoadedTableFuncs, documents])

    const onDraggableEnd = useCallback(
        async (result) => {
            const {destination, source} = result || {}
            if (!destination || !source || destination.index === source.index) {
                return
            }
            moveItem(source.index, destination.index)
        },
        [moveItem]
    )

    return (
        <div>
            <KlassappTable
                columns={columns}
                menuActions={[]}
                data={data}
                isLoading={isLoading}
                fields={fields}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn={false}
                isDraggable
                onChangeFields={onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
                onDraggableEnd={onDraggableEnd}
            />
        </div>
    )
}

export default KlassappTableHOC(PrintPrepareDocumentsTable)
