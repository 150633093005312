import {observable} from "mobx"
import {Action, History, Location} from "history"
import moment from "moment-timezone"
import {Gate} from "./lib/gate/Gate"
import {api_response, NewCalendarEvent, Visitor} from "./lib/gate/interfaces"
import {
    authService,
    BaseService,
    calendarService,
    chatService,
    coreSocket,
    departmentsService,
    rolePermissionService,
    settingsService,
    smsService,
    studentActivityLogService,
    userService,
    userServiceV3
} from "services"
import {Auth} from "types/auth"
import {Settings} from "types/settings"
import {LabelDate, Message, Poll} from "types/chat"
import {enAU, es} from "date-fns/locale"
import {format} from "date-fns"
import enLocale from "@fullcalendar/core/locales/en-gb"
import esLocale from "@fullcalendar/core/locales/es"
import {SlugHelper} from "SlugHelper"
import {ProfilePermissionsDetails} from "@edular/permissions"
import {store, toastInfo} from "helpers"
import * as Sentry from "@sentry/react"
import {ViewType} from "sections/shared/students"
import {FilterKey} from "types/filter"
import {SOCKET_PATH_EDULAR_CORE} from "services/SocketClient"
import {get, head, orderBy, pick} from "lodash"
import {KEY_PRIORITY_COLLAPSIBLE, UNLIMITED_PAGE_SIZE} from "data/constants"
import {ActivityLogType} from "types/student-activity-log"

export class Model {
    @observable
    public visitor: Visitor

    @observable
    public slug: string

    @observable
    public isLoggedIn: boolean = false

    @observable
    public authToken: string

    @observable
    public profileId: number

    @observable
    public path: string

    @observable
    public location: Location

    @observable
    public prevLocation: Location

    @observable
    public currentLocation: Location

    @observable
    public user: Auth.UserProfile

    @observable
    public appSettings: any

    @observable
    public activeSchedules = []

    @observable
    public selectedLocations: api_response.Locations[]

    @observable
    public locations: api_response.Locations[] = []

    @observable
    public allPrograms: api_response.Program[] = []

    @observable
    public selectedPrograms: api_response.Program[] = []

    @observable
    public allCourses: api_response.Courses[] = []

    @observable
    public selectedCourses: api_response.Courses[] = []

    @observable
    public resourceTypes: any

    @observable
    public departments: any = []

    @observable
    public fullScreenMode: boolean = false

    @observable
    public calendars: any[] = []

    @observable
    public calendarEvents: NewCalendarEvent[] = []

    @observable
    public timeZones: any[] = []

    @observable
    public dateFormat: any

    @observable
    public tableView: any

    @observable
    public dateTimeFormat: any

    @observable
    public timeFormat: string

    @observable
    public courseTypes: any

    @observable
    public contactDetail: any = {}

    @observable
    public contactDetailRoomId: any = {}

    @observable
    public allowedDocumentFormats: any = null

    @observable
    public fieldLabels: Record<string, Settings.FieldLabel> = {}

    @observable
    public calendarEventSelected: NewCalendarEvent = {} as NewCalendarEvent

    @observable
    public clientSetting: Settings.ClientSetting = {} as Settings.ClientSetting

    @observable
    public menu = {infoBar: true, sidebar: true}

    @observable
    public isSubmittingTaskFromEmail = false

    @observable
    public securityLevelEncoded = ""

    @observable
    public smsPhoneNumbers = []

    @observable
    public canSendSms = false
    @observable
    public canSendDirectSms = false
    @observable
    public smsDepartments: number[] = []

    @observable
    public smsAllPhoneNumbers: Array<{
        smsPhoneNumbersId: number
        areaCode?: number
        type: "toll_free" | "local"
        phone: string
        campuses: Array<{id: number; name: string}>
        departments: Array<{departmentId: number; name: string}>
        user?: number
        isGlobal?: number
    }> = []

    @observable
    public myPermissions: ProfilePermissionsDetails

    @observable
    public departmentPermissions: any

    @observable
    public calendarOptions: any = {
        month: "",
        year: "",
        nextMonth: -1,
        prevMonth: -1
    }

    @observable
    public photo: any

    public readonly history: History

    @observable
    public windowFocused = true

    @observable
    public filterUserList: any = {
        search: ""
    }

    public isSsoEnabled: boolean

    private screenCaptureInterval: any

    // chat
    @observable
    public departmentsChats: any = []

    @observable
    public roomList: {[key: string]: Map<number, any>} = {
        private: new Map(),
        channel: new Map(),
        direct_message: new Map()
    }

    @observable
    public users: any = {}

    @observable
    public usersNotFound: {[key: string]: number} = {}

    @observable
    public foundMessages: any = {
        oneRoom: []
    }
    @observable
    public searchUserInChat: string = ""

    @observable
    public fragmentMessages: Map<string | number, Message | LabelDate> = new Map<string | number, Message | LabelDate>()

    @observable
    public pinnedMessages: any[] = []

    @observable
    public foundMessageId: number

    @observable
    public chatOptions: any = {}

    @observable
    public fragmentMessageOptions: any = {
        hasMoreDataOnPrev: true,
        hasMoreDataOnNext: true,
        showingFoundMessage: false
    }

    @observable
    public searchTextInChat: any

    @observable
    public notificationUnreadCount = 0

    @observable
    public viewOtherUserChat = null

    // end chat

    // calendar

    @observable
    public viewOtherUserCalendar = null

    // end calendar

    constructor(private gate: Gate, history: History) {
        this.history = history
        history.listen(this.onHistoryChange.bind(this))
        this.onHistoryChange(history.location, "PUSH")
        this.courseTypes = this.initCourseTypes()
        this.generateSlug()
    }

    public loadSettings = async () => {
        try {
            this.clientSetting = await settingsService.getClientSettings({slug: this.slug})
        } catch (e) {
            throw e
        }
    }

    public loadAllowedDocumentFormats = async () => {
        const formats = await settingsService.getAllowedDocumentFormats()
        this.allowedDocumentFormats = formats.map((allowedFormat) => allowedFormat.mimetype).join(", ")
    }

    public loadFieldLabels = async () => {
        const fieldLabels = await userService.getLabels()
        this.fieldLabels = fieldLabels
    }

    public getAllowedDocumentFormats = () => {
        return this.allowedDocumentFormats
    }

    public updatePublicBranding = async (publicBranding: Settings.PublicBranding) => {
        this.clientSetting.branding = publicBranding
    }

    public getScreenShot = () => {
        const video: any = document.getElementById("hiddenVideo")
        const canvas: any = document.getElementById("hiddenCanvas")

        if (!video) {
            return
        }
        canvas.width = video.videoWidth
        canvas.height = video.videoHeight

        canvas.getContext("2d").drawImage(video, 0, 0, video.videoWidth, video.videoHeight) // for drawing the video element on the canvas

        return canvas.toDataURL("image/jpeg")
    }

    public screenCapture = async (asyncUpdateFunction) => {
        this.screenCaptureInterval = setInterval(async () => {
            const base64Image = this.getScreenShot()
            await asyncUpdateFunction(base64Image)
        }, 3000)
    }

    public stopScreenCapture = async () => {
        clearInterval(this.screenCaptureInterval)
    }

    public loadMyPermissions = async () => {
        try {
            const [myPermissions, departmentPermissions] = await Promise.all([
                rolePermissionService.getMyPermissions(),
                rolePermissionService.getDepartmentPermissions()
            ])
            this.myPermissions = myPermissions
            this.departmentPermissions = departmentPermissions
        } catch (error) {
            throw error
        }
    }

    public updateUserTimeZone = async (): Promise<void> => {
        if (this.isLoggedIn && this.user?.id) {
            const timeZone = moment.tz.guess()
            await userServiceV3.update({
                id: this.user.id,
                timeZone
            })
        }
    }

    public generateSlug() {
        this.slug = SlugHelper.generate()
    }

    public getSlug() {
        return this.slug
    }

    private initCourseTypes() {
        return [
            {
                id: 1,
                name: "Hours"
            },
            {
                id: 2,
                name: "Credit"
            },
            {
                id: 3,
                name: "Each"
            }
        ]
    }

    public async loadSmsPhoneNumbers() {
        const {phones} = await smsService.me({})
        const {data: allPhones} = await smsService.getNumbers({
            range: {
                limit: UNLIMITED_PAGE_SIZE,
                offset: 0
            }
        })
        this.smsAllPhoneNumbers = allPhones ?? []
        this.smsPhoneNumbers = phones
        this.smsDepartments = []
        this.canSendSms = !!this.smsPhoneNumbers.length
        this.smsPhoneNumbers.forEach((phone) => {
            if (phone.smsPhoneNumbersUsersId) {
                this.canSendDirectSms = true
            } else {
                this.smsDepartments.push(phone.departmentId)
            }
        })
    }

    public setUserStorage(user) {
        const profiles = (user.profiles || []).map(({id, isAccessDisabled, active, type}) => ({
            id,
            isAccessDisabled,
            active,
            type
        }))
        const data: any = pick(user, ["id", "firstName", "lastName", "emails", "dateFormat"])
        data.profiles = profiles
        store.set("user", data)
    }

    public async loadUserInfo() {
        try {
            const userInfo = store.get("user")
            const user = await userServiceV3.getOne(userInfo.id)
            this.user = user
            this.setUserStorage(user)
        } catch (error) {
            //
        }
    }

    public loadSession() {
        const token = store.get("authToken")
        const user = store.get("user")
        const serializedProfileId = store.get("profileId")
        const profileId = serializedProfileId ? +serializedProfileId : null

        if (user) {
            const userId = String(user.id)
            const email = user.emails?.[0]?.email
            const username = `${user.firstName} ${user.lastName}`
            const currentProfileId = String(profileId)

            Sentry.setUser({
                id: userId,
                email: email,
                username,
                profileId: currentProfileId,
                slug: this.slug
            })
        }

        this.setUserToken(user, token, profileId)
    }

    public loadSocket() {
        const token = store.get("authToken")
        const user = store.get("user")
        const serializedProfileId = store.get("profileId")
        const profileId = serializedProfileId ? +serializedProfileId : null

        if (user) {
            coreSocket.setUserId(user.id)
            coreSocket.setProfileId(profileId)
            coreSocket.setAccessToken(token)
            coreSocket.initSocket(
                `${process.env.REACT_APP_EDULAR_CORE_API_BASE_URL}/${this.slug}`,
                SOCKET_PATH_EDULAR_CORE
            )
        }
    }

    private setUserToken(user: Auth.UserProfile, token, profileId: number) {
        if (user && token && profileId) {
            try {
                this.user = user
                this.profileId = profileId
                this.authToken = token
                this.isLoggedIn = true
                BaseService.setAuthToken(token)
                BaseService.setProfileId(profileId)
            } catch (e) {
                store.remove("user")
            }
        }
    }

    public async logout() {
        const deviceId = store.get("deviceId")
        await studentActivityLogService.createActivityLog([
            {
                userId: this.user.id,
                type: ActivityLogType.AuthLogOutFromWeb,
                targetId: this.user.id,
                data: {user: this.user}
            }
        ])
        store.clearAll()
        store.set("deviceId", deviceId)
        this.user = null
        this.profileId = null
        this.authToken = null
        this.isLoggedIn = false

        Sentry.setUser(null)
        window.location.reload()
    }

    public getUserProfileType() {
        let currentProfile = null
        if (this.user) {
            currentProfile = this.user.profiles.find((profile) => profile.id === this.profileId)
        }
        return currentProfile && currentProfile.type
    }

    public getCurrentUserProfile() {
        return this.user ? this.user.profiles.find((profile) => profile.id === this.profileId) : null
    }

    public getUserProfileState() {
        let currentProfile = null
        if (this.user) {
            currentProfile = this.user.profiles.find((profile) => profile.id === this.profileId)
        }
        return currentProfile && currentProfile.state
    }

    public getLocale(): string {
        return store.get("locale") || "en"
    }

    public getDateFnsLocale() {
        const locales = {
            en: enAU,
            de: es
        }
        return locales[this.getLocale()]
    }

    public getFullCalendarLocale() {
        const locales = {
            en: enLocale,
            de: esLocale
        }
        return locales[this.getLocale()]
    }

    public getUserEmail() {
        let email = null
        if (this.user) {
            email = this.user.emails.find((email) => email && email.isPrimary === 1)
        }
        return email && email.email
    }

    public updateUser(key: string, value: any): void {
        this.user = {...this.user, [key]: value}
    }

    public loadPhoto(photo: string | null) {
        const token = store.get("authToken")
        if (this.user && token) {
            if (photo) {
                const photoParsed = JSON.parse(photo)
                this.user = {...this.user, photo: photoParsed}
                this.photo = photoParsed.original
            } else {
                this.user = {...this.user, photo}
                this.photo = photo
            }
        }
    }

    public isAdmin() {
        return !!this.user?.profiles?.find(
            (profile) => this.profileId === profile.id && profile.active && profile.type === Auth.UserProfileType.Admin
        )
    }

    public isStaffOrAdmin() {
        let currentProfile = null
        if (this.user) {
            currentProfile = this.user.profiles?.find((profile) => profile.id === this.profileId)
        }
        if (
            currentProfile &&
            (currentProfile.type === Auth.UserProfileType.Staff || currentProfile.type === Auth.UserProfileType.Admin)
        ) {
            return true
        }
        return false
    }

    public isStudent() {
        return !!this.user?.profiles?.find(
            (profile) =>
                this.profileId === profile.id && profile.active && profile.type === Auth.UserProfileType.Student
        )
    }

    public isStaff() {
        return !!this.user?.profiles?.find(
            (profile) => this.profileId === profile.id && profile.active && profile.type === Auth.UserProfileType.Staff
        )
    }

    public isOther() {
        return !!this.user?.profiles?.find(
            (profile) => this.profileId === profile.id && profile.active && profile.type === Auth.UserProfileType.Others
        )
    }

    public changeProfile(profileIndex: number) {
        const newProfile = this.user?.profiles.find((profile) => profile && profile.id === profileIndex)

        if (newProfile) {
            store.set("profileId", newProfile.id.toString())
            BaseService.setProfileId(newProfile.id)
            this.profileId = newProfile.id

            const userId = String(this.user.id)
            const email = this.user.emails?.[0]?.email
            const username = `${this.user.firstName} ${this.user.lastName}`
            const currentProfileId = String(newProfile.id)

            Sentry.setUser({
                id: userId,
                email,
                username,
                profileId: currentProfileId,
                slug: this.slug
            })
            window.location.reload()
        }
    }

    public getUserDateFormat() {
        let userDateFormat: string
        if (this.user) {
            const {dateFormat} = this.user
            userDateFormat = dateFormat || this.loadDateFormat()
        }
        return userDateFormat
    }

    public getUserTimeFormat() {
        let userTimeFormat = "HH:mm"
        if (this.user) {
            const {timeFormat} = this.user
            userTimeFormat = timeFormat || this.loadTimeFormat()
            if (userTimeFormat === Auth.TimeFormat.Time_12) {
                return "hh:mm A"
            }
            return "HH:mm"
        }
        return userTimeFormat
    }

    public getUserTableView() {
        let userTableView
        if (this.user) {
            const {tableView} = this.user
            userTableView = tableView || this.loadTableViewDefault()
        }
        return userTableView
    }

    public getStorageFilter(key: FilterKey): any {
        if (this.user.filterMemory && key) {
            return store.get(key)
        }
        return null
    }

    public setStorageFilter(key: FilterKey, value: any): void {
        if (this.user.filterMemory && key) {
            store.set(key, value)
        }
    }

    public updateStorageFilter(filterKey: FilterKey, data: Record<string, any>): void {
        if (this.user.filterMemory && filterKey) {
            let filterData = this.getStorageFilter(filterKey)
            filterData = {...(filterData || {}), ...data}
            store.set(filterKey, filterData)
        }
    }

    public getStorageByKey(key: string): any {
        return store.get(key)
    }

    public setStorageByKey(key: string, value: any): void {
        store.set(key, value)
    }

    public updateStorageByKey(key: string, data: Record<string, any>): void {
        const currentData = this.getStorageByKey(key)
        const newData = {...(currentData || {}), ...data}
        store.set(key, newData)
    }

    public getPriorityViewCollapsible(): {[statusId: number]: boolean} {
        if (this.user.filterMemory) {
            return store.get(KEY_PRIORITY_COLLAPSIBLE, {})
        }
        return {}
    }

    public setPriorityViewCollapsible(state: {[statusId: number]: boolean}): void {
        if (this.user.filterMemory) {
            store.set(KEY_PRIORITY_COLLAPSIBLE, state)
        }
    }

    public updateUserDateFormat(dateFormat: string | null) {
        if (this.user) {
            this.user.dateFormat = dateFormat
        }
    }

    public updateUserTimeFormat(timeFormat: string | null) {
        if (this.user) {
            this.user.timeFormat = timeFormat
        }
    }

    public updateUserTableView(tableView: string | null) {
        if (this.user) {
            this.user.tableView = tableView
        }
    }

    public getUserDateTimeFormat() {
        let userDateTimeFormat: string
        if (this.user) {
            const userDateFormat = this.getUserDateFormat()
            const userTimeFormat = this.getUserTimeFormat()
            userDateTimeFormat = `${userDateFormat} ${userTimeFormat}`
        }
        return userDateTimeFormat
    }

    public updateUserDateTimeFormat(format: string | null) {
        if (this.user) {
            this.user.dateTimeFormat = format
        }
    }

    public formatWithDateFns(date) {
        return format(date, this.getUserDateFormat().replace(/YYYY/gi, "yyyy").replace(/DD/gi, "dd"))
    }

    /**
     * Auth
     */

    public async getOtp(data, params = {}) {
        return await authService.getOtp(data, params)
    }

    public async signIn(params: Auth.SignInParams): Promise<Auth.SignInResponse> {
        try {
            const data = await authService.signIn(params)
            if (get(data, "user")) {
                const {user, token} = data as Auth.SignInNormal
                this.setLocalStorage(user, token)
            }
            return data
        } catch (e) {
            throw e
        }
    }

    public async otpSignIn(data: Auth.SignInParams): Promise<Auth.SignInNormal> {
        try {
            const res = await authService.otpSignIn(data)
            this.setLocalStorage(res.user, res.token)
            return res
        } catch (e) {
            throw e
        }
    }

    public async updateUserInfo(): Promise<void> {
        try {
            const token = store.get("authToken")
            if (!token) return
            const user = await authService.getMe()
            this.setLocalStorage(user, token)
        } catch (e) {
            throw e
        }
    }

    public setLocalStorage(user: any, token: string) {
        const sortedProfiles = orderBy(user.profiles, "active", "desc").filter((profile) => !profile.isAccessDisabled)
        const defaultProfile = sortedProfiles.find((profile) => profile.isDefault) || head(sortedProfiles)

        store.set("authToken", token)
        this.setUserStorage(user)
        store.set("profileId", defaultProfile.id.toString())
        this.setUserToken(user, token, defaultProfile.id)
    }

    public async setStorage(token: string, userId: number, profileId: number) {
        try {
            store.set("authToken", token)
            store.set("profileId", profileId.toString())
            store.set("user", {id: userId})
            this.loadSession()
            const user = await authService.getMe()
            this.setUserStorage(user)
            this.photo = JSON.parse(user.photo || "{}")
            this.loadSession()
            await Promise.all([this.loadMyPermissions, this.loadDepartments()])
        } catch (e) {
            throw e
        }
    }

    public async clearStorage() {
        store.remove("authToken")
        store.remove("profileId")
        store.remove("user")
    }

    private onHistoryChange(location: Location, action: Action) {
        this.path = location.pathname
        this.location = location
    }

    public loadResourceTypes() {
        this.resourceTypes = [
            {
                id: 1,
                name: "Room"
            },
            {
                id: 2,
                name: "Lab"
            },
            {
                id: 3,
                name: "Equipment"
            },
            {
                id: 4,
                name: "Instrument"
            }
        ]
    }

    public getContactRoles() {
        return [
            {id: "Parent / Guardian", name: "Parent / Guardian"},
            {id: "Spouse", name: "Spouse"},
            {id: "Sponsor", name: "Sponsor"},
            {id: "Employer", name: "Employer"},
            {id: "Companion", name: "Companion"},
            {id: "Primary Doctor", name: "Primary Doctor"},
            {id: "Power of Attorney", name: "Power of Attorney"}
        ]
    }

    public async loadDepartments() {
        const {data: departments} = await departmentsService.getAll({sort: {orderBy: "name", orderDir: "asc"}})
        this.departments = departments.map((department: any) => ({
            ...department,
            id: department.departmentId
        }))
        await this.loadDepartmentChats()
    }

    public loadDateFormat() {
        const format = "MM/DD/YYYY"
        this.dateFormat = format
        return format
    }

    public loadTableViewDefault() {
        const view: ViewType = "standard"
        this.tableView = view
        return view
    }

    public loadTimeFormat() {
        const format = "24 H"
        this.timeFormat = format
        return format
    }

    public setPrevLocation(location: Location) {
        this.prevLocation = location
    }

    public setCurrentLocation(location: Location) {
        this.prevLocation = this.currentLocation
        this.currentLocation = location
    }

    public loadDateTimeFormat() {
        const format = "MM/DD/YYYY hh:mm A"
        this.dateTimeFormat = format
        return format
    }

    public async loadCalendars(params = {}) {
        const data = {
            filter: {
                owner_uid: this.user.id
            }
        }
        const response = await calendarService.getAllCalendars(data, params)

        this.calendars = response.data.map((calendar) => {
            if (!calendar.json_data) {
                calendar.json_data = {}
            }
            calendar.json_data.isActive = false
            return calendar
        })
        return this.calendars
    }

    public async addCalendar(data, params = {}) {
        return await calendarService.addCalendar(data, params)
    }

    public async removeEventsFromCalendar(calendarId) {
        this.calendarEvents = this.calendarEvents.filter((e) => e.calendar_id !== calendarId)
    }

    public async addEvent(data) {
        return await calendarService.addObject(data)
    }

    public async removeEvent(id) {
        return await calendarService.deleteEvent(id, {
            target_filter: "only_this"
        })
    }

    // chat

    public async showInfoProfileChat(userId: number) {
        if (Object.keys(this.users).includes(`${userId}`)) {
            this.closeRightSide()
            this.updateChatOptions("userDetailId", userId)
            this.updateChatOptions("showRightSide", true)
        }
    }

    public showChatDetails() {
        this.closeRightSide()
        this.updateChatOptions("showChatDetails", true)
        this.updateChatOptions("showRightSide", true)
    }

    public showPinnedMessages() {
        this.closeRightSide()
        this.updateChatOptions("showRightSide", true)
        this.updateChatOptions("showPinnedMessages", true)
    }

    public showMembers() {
        this.closeRightSide()
        this.updateChatOptions("showRightSide", true)
        this.updateChatOptions("showMembers", true)
    }

    public showFiles() {
        this.closeRightSide()
        this.updateChatOptions("showRightSide", true)
        this.updateChatOptions("showFiles", true)
    }

    public hideRightSideSections(items: string[]) {
        items.forEach((item) => {
            this.updateChatOptions(item, false)
        })
    }

    public closeRightSide() {
        this.updateChatOptions("showRightSide", false)
        this.updateChatOptions("showMembers", false)
        this.updateChatOptions("showPinnedMessages", false)
        this.updateChatOptions("showFiles", false)
        this.updateChatOptions("showConversationSearch", false)
        this.updateChatOptions("userDetailId", undefined)
        this.updateChatOptions("showChatDetails", false)
        this.updateChatOptions("showDirectMessageParticipants", false)
    }

    public clearResults() {
        this.foundMessages = {
            oneRoom: [],
            oneRoomText: ""
        }
    }

    private addDepartmentsInRooms = (departments: Array<any>) => {
        departments.forEach((d) => {
            if (!this.roomList[`department${d.id}`]) {
                this.roomList[`department${d.id}`] = new Map()
            }
        })
    }

    public async loadDepartmentChats() {
        const newDepartments = this.departments.filter((department: any) => department.isDepartmentalChatEnabled)
        this.departmentsChats = newDepartments
        this.addDepartmentsInRooms(newDepartments)
    }

    private prepareRoomList = (roomList) => {
        if (!roomList.length) return
        const roomType = roomList[0].room.type
        roomList.forEach((item) => {
            if (!this.roomList[roomType]?.has(item.room.room_id)) {
                const users = item.room.user_ids.reduce((usersObject, userId) => {
                    usersObject[userId] = this.users[userId]
                    this.users[userId] = {
                        ...(this.users[userId] || {}),
                        onlineAt: item.room.users_status.find((userStatus) => userStatus.user_id === userId)?.online_at
                    }
                    return usersObject
                }, {})
                item.users = users
                if (
                    item.room.type !== "channel" &&
                    item.room.type !== "direct_message" &&
                    !item.room.type.startsWith("department")
                ) {
                    const roomName = Object.values(users)
                        .filter((user: any) => user?.id)
                        .filter((user: any) => ![+this.user.id, this.viewOtherUserChat?.id].includes(user.id))
                        .map((user: any) => this.users[user.id]?.fullName)
                        .join(", ")
                    item.room.name = roomName || item.room.name
                }
                if (item.room.type.startsWith("department")) {
                    let roomName = item.room.name
                    const userIds = Object.values(users)
                        .filter((user: any) => user?.id)
                        .map((user: any) => user.id)

                    if (
                        userIds.includes(this.user.id) &&
                        this.user.profiles.find((p) => p.type === Auth.UserProfileType.Student)
                    ) {
                        roomName = item.room.json_data.department_name
                    }
                    item.room.name = roomName
                }
                if (item.room.type === "direct_message") {
                    const roomName = `${moment(item.room.created_at).format("MM/DD/YYYY HH:mm")}, ${
                        item.room.name
                    } participants`
                    item.room.name = roomName
                }
                if (this.viewOtherUserChat) {
                    item.room.can_write = false
                }
                this.roomList[roomType].set(item.room.room_id, item)
            }
        })
        this.updateNotificationUnreadCount()
    }

    public updateRoomList(newRoomList) {
        this.prepareRoomList(newRoomList)
    }

    public updateOneRoomList(newMessage) {
        const roomType = newMessage.room_type
        if (!this.chatOptions.chatMounted && newMessage.from_user_id !== this.user.id) {
            this.updateChatOptions("unreadCount", this.chatOptions.unreadCount + 1)
        }
        if (this.roomList[roomType]?.has(newMessage.room_id)) {
            const room = this.roomList[roomType].get(newMessage.room_id)
            const updatedItem = {
                ...room,
                message: newMessage
            }
            if (
                newMessage.from_user_id !== this.user.id &&
                (newMessage.room_id !== this.chatOptions.activeRoomId || !this.windowFocused)
            ) {
                updatedItem.unread = (room.unread || 0) + 1
            }
            const copy: any = new Map(this.roomList[roomType])
            const pinnedRooms: any = Array.from(copy.entries()).filter(([, r]: any) => r.room.json_data.order)
            const newRoomList: any = new Map()
            pinnedRooms.forEach(([, r]: any) => {
                newRoomList.set(r.room.room_id, r)
                copy.delete(r.room.room_id)
            })
            copy.delete(newMessage.room_id)
            newRoomList.set(newMessage.room_id, updatedItem)
            this.roomList[roomType] = new Map([...newRoomList, ...copy])
        }
        this.updateNotificationUnreadCount()
    }

    public async updateUsersOfRoom(roomId: number, roomType: string, userIds: number[]) {
        await this.getUsers(userIds)
        if (this.roomList[roomType]?.has(roomId)) {
            const currentRoom = this.roomList[roomType].get(roomId)
            const users = userIds.reduce((usersObject, userId) => {
                usersObject[userId] = this.users[userId]
                return usersObject
            }, {})
            currentRoom.users = users
            currentRoom.room.user_ids = userIds
            this.roomList[roomType].set(roomId, currentRoom)
        }
    }

    public removeUsersFromRoom(roomId: number, userIds: number[]) {
        let found
        let type = ""
        Object.entries(this.roomList).forEach(([roomType, roomMap]: any) => {
            if (roomMap.has(roomId)) {
                found = roomMap.get(roomId)
                type = roomType
            }
        })
        if (found) {
            const newUsers = Object.keys(found.users || {})
                .filter((userId) => !userIds.includes(+userId))
                .map((userId) => +userId)
            if (newUsers.length > 0) {
                this.updateUsersOfRoom(roomId, type, newUsers)
            }
        }
        this.updateNotificationUnreadCount()
    }

    public moveRoomToBeginning(room) {
        const roomType = room.type
        const prevRoom = this.roomList[roomType].get(room.room_id)
        const copy: any = new Map(this.roomList[roomType])
        const pinnedRooms: any = Array.from(copy.entries()).filter(([, r]: any) => r.room.json_data.order)
        const newRoomList: any = new Map()
        pinnedRooms.forEach(([, r]: any) => {
            newRoomList.set(r.room.room_id, r)
            copy.delete(r.room.room_id)
        })
        copy.delete(room.room_id)
        newRoomList.set(room.room_id, prevRoom)
        this.roomList[roomType] = new Map([...newRoomList, ...copy])
    }

    public async updateRoomPermissions(room) {
        const roomType = room.type
        if (this.roomList[roomType]?.has(room.room_id)) {
            const {data: newRoom} = await chatService.getOneRoom(room.room_id)
            const currentRoom = this.roomList[roomType].get(room.room_id)
            currentRoom.room.can_write = newRoom.can_write
            this.roomList[roomType].set(room.room_id, currentRoom)
        }
    }

    public getCountNoReadMessages() {
        if (!this.chatOptions.chatMounted) {
            return this.chatOptions.unreadCount
        }
        let total = 0
        let totalChannelNotification = 0
        Object.entries(this.roomList).forEach(([_, value]: any) => {
            const unreadMessages = [...value.values()].reduce((acc, cur: any) => {
                return acc + (cur.room.json_data?.type !== "notification" ? cur.unread : 0)
            }, 0)
            if (_ === "channel") {
                const channelNotification = [...value.values()].find((r) => r.room.json_data?.type === "notification")
                totalChannelNotification = channelNotification?.unread || 0
            }
            total = total + unreadMessages
        })
        this.updateChatOptions("unreadCount", total)
        this.updateChatOptions("unreadCountChannelNotification", totalChannelNotification)
        return total
    }

    public updateUnreadCounterRoomList(roomId) {
        let room = null
        let roomType = null
        if (this.roomList.private.has(roomId)) {
            room = this.roomList.private.get(roomId)
            roomType = "private"
        }
        if (this.roomList.channel.has(roomId)) {
            room = this.roomList.channel.get(roomId)
            roomType = "channel"
        }
        if (this.roomList.direct_message.has(roomId)) {
            room = this.roomList.direct_message.get(roomId)
            roomType = "direct_message"
        }
        const departmentRooms = Object.keys(this.roomList).filter((r) => r.startsWith("department"))
        if (departmentRooms.length) {
            departmentRooms.forEach((rType) => {
                if (this.roomList[rType].has(roomId)) {
                    room = this.roomList[rType].get(roomId)
                    roomType = rType
                    return
                }
            })
        }

        this.updateNotificationUnreadCount()

        if (!room) {
            return
        }

        room.unread = 0
        room.markAsUnreadMessage = undefined
        this.chatOptions.markAsUnreadMessage = undefined
        this.roomList[roomType].set(roomId, room)
        this.updateNotificationUnreadCount()
    }

    public updateUsers = (newUsers) => {
        newUsers.forEach((item) => {
            this.users[item.id] = {
                ...(this.users[item.id] || {}),
                ...item
            }
        }, {})
    }

    public async getUsers(userIds = []) {
        const currentIds = Object.values(this.users)
            .map((user: any) => user.id)
            .filter(Boolean)
        const ids = userIds
            .filter(Boolean)
            .filter((userId) => !currentIds.includes(userId))
            .filter((userId) => !this.usersNotFound[userId])
        if (ids.length) {
            const {data} = await userServiceV3.getAllAutocomplete({
                filter: {
                    id: ids
                },
                range: {
                    pageSize: ids.length,
                    page: 1
                }
            })
            if (data.length === 0) {
                ids.forEach((id) => {
                    this.usersNotFound[id] = id
                })
            }
            data.forEach((item) => {
                this.users[item.id] = {
                    ...(this.users[item.id] || {}),
                    ...item
                }
            }, {})
        }
    }

    public getOneUser(userId) {
        if (!userId) {
            return {
                fullName: "Notifications",
                firstName: "Notifications"
            }
        }
        if (this.users[userId]?.id === undefined) {
            return this.getUsers([userId]).then(() => {
                return this.users[userId]
            })
        }
        return this.users[userId]
    }

    public async getOneCompleteUser(userId) {
        const {data} = await userServiceV3.getAll({
            filter: {
                id: [userId]
            },
            range: {
                limit: 1,
                offset: 0
            },
            linkedObjects: true
        })
        return data[0]
    }

    public updateFoundMessages(key, data) {
        this.foundMessages[key] = data
    }

    public updateMarkUnreadFromMessageId({messages}) {
        messages.forEach((message) => {
            if (this.fragmentMessages.has(message.message_id)) {
                this.updateFragmentMessagesOneMessageField(message.message_id, "read_status", message.read_status)
            }
        })
    }

    public updateUnreadCounter(room_id, newCount) {
        const room = {...this.roomList[this.chatOptions.activeRoomType].get(room_id)}
        room.unread = newCount
        this.roomList[this.chatOptions.activeRoomType].set(room_id, room)
        this.updateNotificationUnreadCount()
    }

    public saveUnreadMessageReference({room_id, message_id}) {
        const room = {...this.roomList[this.chatOptions.activeRoomType].get(room_id)}
        room.markAsUnreadMessage = message_id
        this.chatOptions.markAsUnreadMessage = message_id
        this.roomList[this.chatOptions.activeRoomType].set(room_id, room)
    }

    public async markAsReadMultiple(messageId, direction: "forward" | "backward" | "equals" = "forward") {
        const hasUnread = !!this.roomList[this.chatOptions.activeRoomType].get(this.chatOptions.activeRoomId).unread
        if (hasUnread && !this.viewOtherUserChat?.id) {
            const response = await chatService.markAsReadMultiple({
                room_id: this.chatOptions.activeRoomId,
                last_read_message_id: messageId,
                direction
            })
            if (response.success) {
                this.updateUnreadCounterRoomList(this.chatOptions.activeRoomId)
                this.updateMarkReadFromMessageId(response.data)
            }
        }
    }

    public async markAsReadUnreadMessages(roomId) {
        const room = this.roomList[this.chatOptions.activeRoomType].get(roomId)
        if (room.markAsUnreadMessage && !this.viewOtherUserChat?.id) {
            await this.markAsReadMultiple(room.markAsUnreadMessage)
        }
    }

    public updateMarkReadFromMessageId({messages}) {
        messages.forEach((message) => {
            if (this.fragmentMessages.has(message.message_id)) {
                this.updateFragmentMessagesOneMessageField(message.message_id, "read_status", message.read_status)
            }
        })
    }

    public updateFragmentMessages(data, fragment) {
        let previousFragmentMessages = Array.from(this.fragmentMessages.values()).filter(
            (message) => message.type !== "label_date"
        )
        let mergedFragmentMessages = []
        if (fragment === "prev") {
            mergedFragmentMessages = [...data, ...previousFragmentMessages]
        } else if (fragment === "next") {
            mergedFragmentMessages = [...previousFragmentMessages, ...data]
        }

        this.resetFragmentMessages(mergedFragmentMessages)
    }

    public updateFragmentMessagesOneMessage(message) {
        if (this.fragmentMessages.has(message.message_id)) {
            this.fragmentMessages.set(message.message_id, message)
        }
    }

    public updateFragmentMessagesOneMessageField(message_id, key, value) {
        if (this.fragmentMessages.has(message_id)) {
            const message = {...this.fragmentMessages.get(message_id)}
            message[key] = value
            this.fragmentMessages.set(message_id, message)
        }
    }

    public updatePinnedMessage(data) {
        const {
            message_id,
            json_data: {is_pinned}
        } = data
        if (this.fragmentMessages.has(message_id)) {
            const message = {...this.fragmentMessages.get(message_id)} as Message
            message.json_data = {
                ...message.json_data,
                is_pinned
            }
            this.fragmentMessages.set(message_id, message)
        }
        if (is_pinned) {
            this.updatePinnedMessages(data)
        } else {
            this.removePinnedMessage(data)
        }
    }

    private formatDateChat = (date) => {
        return moment(date).format("ddd, D MMM")
    }

    public resetFragmentMessages(data: Array<Message>) {
        const newMap = new Map<string | number, Message | LabelDate>()
        if (data.length) {
            data.sort((a, b) => {
                return +new Date(a.created_at) - +new Date(b.created_at)
            })
            let {created_at} = data[0]
            newMap.set(created_at, {
                type: "label_date",
                value: this.formatDateChat(created_at)
            })
            data.forEach((message) => {
                if (this.formatDateChat(created_at) !== this.formatDateChat(message.created_at)) {
                    newMap.set(message.created_at, {
                        type: "label_date",
                        value: this.formatDateChat(message.created_at)
                    })
                    created_at = message.created_at
                }
                newMap.set(message.message_id, message)
            })
            this.fragmentMessages = newMap
        }
    }

    public closeChat() {
        this.chatOptions = {
            chatMounted: true,
            unreadCount: 0,
            unreadCountChannelNotification: 0
        }
    }

    public updateChatOptions(key, value) {
        const newChatOptions = {
            ...this.chatOptions
        }
        newChatOptions[key] = value
        this.chatOptions = newChatOptions
    }

    public updatePinnedMessages(message) {
        const messages = [...this.pinnedMessages, message]
        this.resetPinnedMessages(messages.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))))
    }

    public removePinnedMessage(message) {
        const messages = [...this.pinnedMessages].filter((m) => m.message_id !== message.message_id)
        this.resetPinnedMessages(messages.sort((a, b) => moment(b.created_at).diff(moment(a.created_at))))
    }

    public resetPinnedMessages(data) {
        this.pinnedMessages = data
        this.updateChatOptions("lastPinnedMessage", data[0])
        if (data.length === 0) {
            this.closeRightSide()
        }
    }

    public updateCalendarOptions(key, value) {
        this.calendarOptions[key] = value
    }

    public saveLastMessage() {
        if (this.chatOptions.activeRoomId) {
            const messages = Array.from(this.fragmentMessages.values())
            const lastMessage: any = messages.length ? messages[messages.length - 1] : {}
            this.updateChatOptions("lastMessage", {
                messageId: this.chatOptions.markAsUnreadMessage || lastMessage.message_id,
                roomId: this.chatOptions.activeRoomId
            })
        }
    }

    public updateNotificationUnreadCount() {
        this.notificationUnreadCount = this.getCountNoReadMessages()
        let prevTitle = document.title
        if (prevTitle.startsWith("(")) {
            prevTitle = prevTitle.replace(/^\(.*\)\s/g, "")
        }
        document.title = `${this.getStringCounter()}${prevTitle}`
    }

    public getStringCounter() {
        let count = ""
        if (this.notificationUnreadCount) {
            count = this.notificationUnreadCount > 99 ? `(+99) ` : `(${this.notificationUnreadCount}) `
        }

        return count
    }

    public updatePollVoters(messageId, poll: Poll) {
        if (this.fragmentMessages.has(messageId)) {
            const message = {...this.fragmentMessages.get(messageId)} as Message
            if (message.poll?.options?.length) {
                message.poll.options = message.poll.options.map((option) => {
                    return {
                        ...option,
                        voters: poll.options.find((opt) => opt.poll_option_id === option.poll_option_id)?.voters || 0
                    }
                })
                message.poll.total_votes = poll.total_votes
                this.fragmentMessages.set(messageId, message)
            }
        }
    }

    public updatePollClosed(messageId: number, poll: Poll) {
        if (this.fragmentMessages.has(messageId)) {
            const message = {...this.fragmentMessages.get(messageId)} as Message
            const {options} = message.poll
            const newPoll = {...poll} as Poll
            if (options?.length) {
                newPoll.options = options.map((option) => {
                    return {
                        ...option,
                        voters: poll.options.find((opt) => opt.poll_option_id === option.poll_option_id)?.voters || 0
                    }
                })
            }
            message.poll = newPoll
            this.fragmentMessages.set(messageId, message)
        }
    }

    public updateOnlineUsers(userIds: number[]) {
        userIds.forEach((userId) => {
            this.users[userId] = {
                ...(this.users[userId] || {}),
                online: true
            }
        })
    }

    public updateUserStatus(data) {
        const {online, online_at, user_id} = data
        this.users[user_id] = {
            ...(this.users[user_id] || {}),
            online,
            onlineAt: online_at
        }
    }

    public async getNotificationChannel() {
        const currentRooms: any = Array.from(this.roomList.channel.values())
        let notificationChannel = currentRooms.find((room) => room?.room?.json_data?.type === "notification")
        if (!notificationChannel) {
            const {data} = await chatService.getSidebar({
                filter: {
                    room_type: "channel"
                },
                pagination: {
                    page: 1,
                    pageSize: 20
                }
            })
            const userIds: number[] = data.reduce((acc, cur) => new Set([...acc, ...cur.room.user_ids]), [])
            await this.getUsers([...userIds])
            this.updateRoomList(data)
            const currentRooms: any = Array.from(this.roomList.channel.values())
            notificationChannel = currentRooms.find((room) => room?.room?.json_data?.type === "notification")
        }
        if (notificationChannel) {
            const activeRoomId = this.chatOptions.activeRoomId
            if (activeRoomId !== notificationChannel.room.room_id) {
                this.fragmentMessages.clear()
                this.closeRightSide()
                this.updateChatOptions("showCreateDirectMessageChat", false)
                this.updateChatOptions("lastPinnedMessage", undefined)
                this.updateChatOptions("activeRoomType", notificationChannel.room.type)
                this.updateChatOptions("activeRoomId", notificationChannel.room.room_id)
                this.updateChatOptions("activeRoom", notificationChannel.room)
                this.foundMessages[`oneRoomText`] = ""
            }
        } else {
            toastInfo("You don't have notifications yet")
        }
    }

    public async getRoom(roomId: number) {
        const isChanel = this.roomList.channel.has(roomId)
        const isPrivate = this.roomList.private.has(roomId)
        const isDirectMessage = this.roomList.direct_message.has(roomId)
        let roomData = null
        let room = null
        let roomType = ""
        if (!isChanel && !isPrivate && !isDirectMessage) {
            const {data} = await chatService.getOneRoom(roomId)
            roomData = data
            if (!roomData) {
                return
            }
            roomType = roomData.type
        } else {
            if (isChanel) {
                room = this.roomList.channel.get(roomId)
                roomType = "channel"
            }
            if (isPrivate) {
                room = this.roomList.private.get(roomId)
                roomType = "private"
            }
            if (isDirectMessage) {
                room = this.roomList.direct_message.get(roomId)
                roomType = "direct_message"
            }
        }
        if (!room) {
            const {data} = await chatService.getSidebar({
                filter: {
                    room_type: roomType
                },
                pagination: {
                    page: 1,
                    pageSize: 20
                }
            })
            const userIds: number[] = data.reduce((acc, cur) => new Set([...acc, ...cur.room.user_ids]), [])
            await this.getUsers([...userIds])
            this.updateRoomList(data)
            room = this.roomList[roomType].get(roomId)
        }
        if (room) {
            const activeRoomId = this.chatOptions.activeRoomId
            if (activeRoomId !== room.room.room_id) {
                this.fragmentMessages.clear()
                this.closeRightSide()
                this.updateChatOptions("showCreateDirectMessageChat", false)
                this.updateChatOptions("lastPinnedMessage", undefined)
                this.updateChatOptions("activeRoomType", room.room.type)
                this.updateChatOptions("activeRoomId", room.room.room_id)
                this.updateChatOptions("activeRoom", room.room)
                this.foundMessages[`oneRoomText`] = ""
            }
        }
    }

    public clearChatData() {
        const newRoomList = {}
        Object.keys(this.roomList).forEach((key) => {
            newRoomList[key] = new Map()
        })
        this.roomList = newRoomList
        this.departmentsChats = []
        this.foundMessages = {
            oneRoom: []
        }
        this.searchUserInChat = ""
        this.fragmentMessages = new Map<string | number, Message | LabelDate>()
        this.pinnedMessages = []
        this.foundMessageId = null
        this.chatOptions = {}
        this.fragmentMessageOptions = {
            hasMoreDataOnPrev: true,
            hasMoreDataOnNext: true,
            showingFoundMessage: false
        }
        this.searchTextInChat = undefined
        // this.notificationUnreadCount = 0
    }

    //end chat
}
