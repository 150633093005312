/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useReducer, useState} from "react"
import {academicPlansService, degreeAuditService} from "services"
import {handleError} from "helpers"
import {useVisible} from "hooks"
import {AcademicPlans} from "types/academicPlans"
import {BaseLoading} from "components"
import {BaseButton} from "components/buttons"
import {Icon} from "components/Icon"
import {Auth} from "types/auth"
import {AddSectionPopup} from "../Popup"
import {CourseSection} from "./CourseSection"
import styles from "./CourseSection.module.css"
import {KlassDropdown} from "components/Select"
import {cloneDeep, uniqBy} from "lodash"
import {FormLabel} from "components/Form"
import {DegreeAuditProgressData} from "types/degreeAudit"

type Props = {
    studentId: number
    isStaff: boolean
    student: Auth.DepartmentStudent
    majorVersionType: string
}

export function ListViewTab(props: Props) {
    function reducer(state, action) {
        return {...state, ...action}
    }
    const initialState = {
        sections: [],
        isLoading: false
    }
    const [progressData, setProgressData] = useState<DegreeAuditProgressData>({
        completed: 0,
        distEdHours: 0,
        attempted: 0,
        appliedToDegree: 0,
        degreeRequirement: 0,
        totalTransferApplied: 0
    })
    const [{sections, isLoading}, dispatch] = useReducer(reducer, initialState)
    const {studentId, majorVersionType, student} = props
    const addSectionPopup = useVisible(false)
    const [listTerms, setListTerms] = useState([])
    const [selectedTerms, setSelectedTerms] = useState([])

    useEffect(() => {
        getListSections()
    }, [])

    const getListSections = async () => {
        try {
            dispatch({isLoading: true})
            const [sections, progressData] = await Promise.all([
                academicPlansService.getListCourseBySection({
                    studentProfileId: studentId,
                    viewType: AcademicPlans.TabKey.List
                }),
                degreeAuditService.getProgress({studentProfileId: studentId})
            ])
            setProgressData(progressData.data)
            const terms = []
            for (let section of sections) {
                const courses = section.courses
                for (let course of courses) {
                    let termName: string = course.termName
                    let termId: any = course.termId
                    let status: string = course.status
                    let credit: number = course.credit
                    let gpa: number = course.gpa
                    let alphabetical: string = course.alphabetical
                    let qualityPoints: number = course.qualityPoints
                    if (course.isTransferCredit) {
                        if (course.termNameTransfer?.length) {
                            termName = course.termNameTransfer
                        }
                        gpa = course.gpaTransfer
                        qualityPoints = course.qualityPointsTransfer
                        alphabetical = course.alphabeticalTransfer
                        termId = termName
                        status = AcademicPlans.AcademicPlanCourseStatus.TransferCompleted
                        credit = course.appliedTransferCredits
                    } else if (course.isPreviouslyCompletedCredit) {
                        if (course.termNameCompleted?.length) {
                            termName = course.termNameCompleted
                        }
                        gpa = course.gpaCompleted
                        qualityPoints = course.qualityPointsCompleted
                        alphabetical = course.alphabeticalCompleted
                        termId = termName
                        status = AcademicPlans.AcademicPlanCourseStatus.PreviouslyCompleted
                        credit = course.appliedCompletedCredits
                    }
                    course.termName = termName
                    course.termId = termId
                    course.status = status
                    course.credit = credit
                    course.gpa = gpa
                    course.alphabetical = alphabetical
                    course.qualityPoints = qualityPoints
                    if (termName?.length) {
                        terms.push({
                            value: termId,
                            label: termName
                        })
                    }
                }
            }
            setListTerms(uniqBy(terms, "value"))
            dispatch({sections})
        } catch (error) {
            handleError(error)
        } finally {
            dispatch({isLoading: false})
        }
    }

    const addSection = () => {
        addSectionPopup.open()
    }

    if (isLoading) {
        return (
            <div className={styles.loadingWrap}>
                <BaseLoading isShow />
            </div>
        )
    }

    return (
        <div>
            <div className={styles.selectTerm}>
                <FormLabel label="Select Term" />
                <KlassDropdown
                    placeholder="Select Term"
                    closeMenuOnSelect={true}
                    value={selectedTerms}
                    isMulti
                    isClearable
                    onChange={(newValue) => setSelectedTerms(newValue)}
                    options={listTerms}
                    labelKey="label"
                    valueKey="value"
                />
            </div>
            {(cloneDeep(sections) || [])
                .map((section) => {
                    if (selectedTerms?.length) {
                        const termIds = selectedTerms.map((term) => term.value)
                        section.courses = section.courses.filter((course) => termIds.includes(course.termId))
                    }
                    return section
                })
                .map((courseSection, index) => (
                    <CourseSection
                        progressData={progressData}
                        key={courseSection.sectionId || index}
                        courseSection={courseSection}
                        studentId={studentId}
                        student={student}
                        reloadView={getListSections}
                        majorVersionType={majorVersionType}
                    />
                ))}
            <BaseButton
                title="Add Section"
                icon={<Icon icon="PLUS" />}
                variant="secondary"
                onClick={addSection}
                className={styles.addSectionBtn}
            />
            {addSectionPopup.isVisible && (
                <AddSectionPopup
                    isShow={addSectionPopup.isVisible}
                    studentId={studentId}
                    majorVersionType={majorVersionType}
                    onClose={addSectionPopup.close}
                    onUpdateSections={getListSections}
                />
            )}
        </div>
    )
}
