/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useState} from "react"
import {useTranslation} from "react-i18next"
import cx from "classnames"
import moment from "moment-timezone"
import {get, isEmpty, orderBy} from "lodash"
import {KlassappTableHeader, KlassappTable, KlassappTableDropdownAsyncPaginate} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {useModel, useVisible} from "hooks"
import {AcademicPlans} from "types/academicPlans"
import {KlassappTableProps} from "types/common"
import styles from "./CourseSectionTable.module.css"
import {handleError} from "helpers"
import {academicPlansService, courseService} from "services"
import {Checkbox} from "antd"
import {Permissions} from "@edular/permissions"
import {useCurrentProfilePermissions} from "hooks"
import {Major} from "types/major"
import {BaseButton} from "components"
import {AddDropVariant} from "types/terms"
import {Auth} from "types/auth"
import {ChangeCourseStatusPopup, RegisterCoursePopup} from "../Popup"
import {getDateOnly, toDateOnly} from "sections/academics/instructional/common/utils"
import {StatusTerm} from "sections/academics/student/DegreeAudit/CurrentProgress/CurrentProgress"

type Props = KlassappTableProps<AcademicPlans.SectionCourse> & {
    coursesData: any[]
    studentId: number
    majorVersionType: string
    student: Auth.DepartmentStudent
    reloadView: () => void
    saveCoursesSuccess: (courses: AcademicPlans.SectionCourse[]) => void
}

function CourseSectionTable(props: Props) {
    const {
        coursesData,
        studentId,
        student,
        saveCoursesSuccess,
        dispatch,
        dispatchFunc,
        page,
        reloadView,
        total,
        pageSize,
        columns,
        menuActions,
        data,
        allFields,
        fields,
        tableHeaderActions,
        isLoading,
        isShowTableHeaderAction,
        orderField,
        getCurrentData,
        majorVersionType
    } = props
    const {t} = useTranslation(["academics", "common"])
    const [allowEdit, setAllowEdit] = useState(false)
    const [isRetaking, setIsRetaking] = useState(false)
    const [activeCoursePopup, setActiveCoursePopup] = useState(null)
    const currentProfilePermissions = useCurrentProfilePermissions()
    const model = useModel()
    const dateFormat = model.getUserDateFormat()
    const changeStatusPopup = useVisible()
    const registerCoursePopup = useVisible()

    const getFields = () => {
        return [
            "Code",
            "Course Name",
            "Grade",
            "Alphabetical",
            "Quality Points",
            "Term",
            "Schedule",
            "Start",
            "End",
            "Credits",
            "Instructor",
            "Mandatory",
            // "Approved alternate(s)",
            "Status"
        ]
    }

    const getPageTitle = () => {
        return "Course"
    }

    const getColumns = () => {
        return [
            {
                title: "Code",
                field: "courseCode",
                sortable: true
            },
            {
                title: "Course Name",
                field: "courseName",
                sortable: true
            },
            {
                title: "Grade",
                field: "gpa"
            },
            {
                title: "Alphabetical",
                field: "alphabetical"
            },
            {
                title: "Quality Points",
                field: "qualityPoints"
            },
            {
                title: "Term",
                field: "termName",
                sortable: true
            },
            {
                title: "Schedule",
                field: "scheduleName",
                sortable: true
            },
            {
                title: "Start",
                field: "firstDayOfCourseValue",
                sortable: true
            },
            {
                title: "End",
                field: "lastDayOfCourseValue",
                sortable: true
            },
            {
                title: majorVersionType === Major.Unit.Clock ? "Clock" : "Credits",
                field: "credit",
                sortable: true
            },
            {
                title: "Instructor",
                field: "courseInstructor",
                sortable: true
            },
            {
                title: "Mandatory",
                field: "isRequiredHtml"
            },
            // {
            //     title: "Approved alternate(s)",
            //     field: "alternateCoursesHtml",
            //     style: {maxWidth: 200}
            // },
            {
                title: "Status",
                field: "statusHtml",
                style: {maxWidth: 400}
            }
        ]
    }

    const getMenuActions = () => {
        if (model.isStaffOrAdmin()) {
            return [
                {
                    title: "Change Status",
                    icon: "EDIT",
                    action: onClickChangeStatus,
                    canShow: canShowChangeStatus
                },
                {
                    title: "Register",
                    icon: "REGISTERED",
                    canShow: canShowRegisterCourse,
                    action: (item) => {
                        registerCoursePopup.open()
                        setActiveCoursePopup(item)
                    }
                }
            ]
        }
        return []
    }

    const onClickChangeStatus = (item) => {
        changeStatusPopup.open()
        setActiveCoursePopup(item)
    }

    const canShowChangeStatus = (data) => {
        const {reviewStatus} = data
        return reviewStatus !== AcademicPlans.AcademicPlanCourseReviewStatus.Approved
    }

    const canShowRegisterCourse = (data) => {
        const {reviewStatus, registeredDate, registeredScheduleId} = data
        return (
            reviewStatus === AcademicPlans.AcademicPlanCourseReviewStatus.Approved &&
            !registeredDate &&
            !registeredScheduleId
        )
    }

    const getTableHeaderActions = () => {
        const data = getCurrentData()
        const checkedData = data.filter((item) => item.isChecked)
        const canShowRetake = checkedData.every((item) => {
            return item.reviewStatus !== AcademicPlans.AcademicPlanCourseReviewStatus.Approved
        })
        const options = [
            {
                title: t("common:action.delete"),
                icon: "DELETE",
                action: onClickDeleteMulti
            }
        ]
        if (canShowRetake) {
            options.push({
                title: "Retake",
                icon: "REPEAT",
                action: onClickRetake
            })
        }
        return options
    }

    const onClickDeleteMulti = async () => {
        const currentData = getCurrentData()
        const courseIds = currentData.filter((item) => item.isChecked).map((course) => course.academicPlanCourseId)
        try {
            await academicPlansService.removeCourse({
                studentProfileId: studentId,
                academicPlanCourseIds: courseIds
            })
            const restCourses = currentData.filter((item) => !item.isChecked)
            saveCoursesSuccess(restCourses)
        } catch (error) {
            handleError(error)
        }
    }

    const onClickRetake = async () => {
        const currentData = getCurrentData()
        const courseIds = currentData.filter((item) => item.isChecked).map((course) => course.academicPlanCourseId)
        try {
            await academicPlansService.retakeCourses({
                studentProfileId: studentId,
                academicPlanCourseIds: courseIds
            })
            reloadView()
        } catch (error) {
            handleError(error)
        }
    }

    const retakeCourse = async (academicPlanCourseIds: number[]) => {
        if (isRetaking) {
            return
        }
        try {
            setIsRetaking(true)
            await academicPlansService.retakeCourses({studentProfileId: studentId, academicPlanCourseIds})
            reloadView()
        } catch (error) {
            handleError(error)
        } finally {
            setIsRetaking(false)
        }
    }

    const renderRetakeButton = (academicPlanCourseId: number) => {
        return (
            <BaseButton
                title="Retake"
                variant="secondary"
                loading={isRetaking}
                onClick={() => retakeCourse([academicPlanCourseId])}
            />
        )
    }

    const renderStatusHtml = (sectionCourse: AcademicPlans.SectionCourse) => {
        const {
            reviewStatus,
            status,
            registeredDate,
            updatedAt,
            droppedDate,
            addDropVariant,
            addDropDate,
            addDropHours
        } = sectionCourse

        if (status === AcademicPlans.AcademicPlanCourseStatus.TransferCompleted) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.completed)}>Completed (T)</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (status === AcademicPlans.AcademicPlanCourseStatus.PreviouslyCompleted) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.completed)}>Completed (P)</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (
            status === AcademicPlans.AcademicPlanCourseStatus.Completed ||
            status === AcademicPlans.AcademicPlanCourseStatus.Finished
        ) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.completed)}>Completed</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (status === AcademicPlans.AcademicPlanCourseStatus.Incomplete) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.completed)}>Incomplete</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (status === AcademicPlans.AcademicPlanCourseStatus.LoaIncomplete) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.completed)}>Loa-Incomplete</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (status === AcademicPlans.AcademicPlanCourseStatus.Inprogress) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.completed)}>In Progress</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (status === AcademicPlans.AcademicPlanCourseStatus.Failed) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.notStarted)}>Failed</span>
                    {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                </div>
            )
        }
        if (status === AcademicPlans.AcademicPlanCourseStatus.Loa) {
            return (
                <div className={styles.statusWrap}>
                    <span className={cx(styles.status, styles.loa)}>LOA</span>
                </div>
            )
        }
        switch (reviewStatus) {
            case AcademicPlans.AcademicPlanCourseReviewStatus.Dropped: {
                const isDropBeforeAddDrop =
                    addDropVariant === AddDropVariant.Date
                        ? moment(droppedDate).isSameOrBefore(moment(addDropDate))
                        : moment(droppedDate).isSameOrBefore(moment(addDropHours))
                return (
                    <div className={styles.statusWrap}>
                        <span
                            className={cx(styles.status, styles.notStarted)}
                            style={{color: isDropBeforeAddDrop ? "#1890ff" : "red"}}>
                            DD (
                            {getDateOnly({
                                date: droppedDate,
                                placeholder: true,
                                dateFormat: model.getUserDateFormat()
                            })}
                            )
                        </span>
                        {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                    </div>
                )
            }
            case AcademicPlans.AcademicPlanCourseReviewStatus.Registered:
                return (
                    <div className={styles.statusWrap}>
                        <span className={cx(styles.status, styles.completed)}>
                            Registered ({moment(registeredDate).format(dateFormat)})
                        </span>
                        {renderRetakeButton(sectionCourse.academicPlanCourseId)}
                    </div>
                )
            case AcademicPlans.AcademicPlanCourseReviewStatus.PendingReview:
                return (
                    <div className={styles.statusWrap}>
                        <span className={cx(styles.status, styles.pendingReview)}>Pending Review</span>
                    </div>
                )
            default:
                return null
        }
    }

    const getData = async () => {
        dispatch({isLoading: true})
        try {
            const newData = coursesData.map((course, index) => {
                course.firstDayOfCourseValue = course.firstDayOfCourse
                    ? moment(toDateOnly(course.firstDayOfCourse)).format(model.getUserDateFormat())
                    : null
                course.lastDayOfCourseValue = course.lastDayOfCourse
                    ? moment(toDateOnly(course.lastDayOfCourse)).format(model.getUserDateFormat())
                    : null
                const isAlternateCourse = !!coursesData.find((itemCourse) =>
                    itemCourse.alternateCourses.find(
                        (alternateCourse) =>
                            alternateCourse.academicPlanSectionId === course.academicPlanSectionId &&
                            alternateCourse.courseId === course.courseId
                    )
                )
                course.isAlternateCourse = isAlternateCourse
                course.id = course.academicPlanCourseId || course.courseId
                course.statusHtml = renderStatusHtml(course)
                course.isRequiredHtml = renderIsRequiredHtml(course, coursesData)
                course.alternateCoursesHtml = renderAlternateCourses(course, coursesData)
                return course
            })
            dispatch({data: newData, total: coursesData.length})
        } catch (e) {
            console.log("e")
        } finally {
            dispatch({isLoading: false})
        }
    }

    const updateMandatory = async (payload) => {
        try {
            dispatch({isLoading: true})
            await academicPlansService.updateAcademicPlanCourse(payload)
            reloadView()
        } catch (err) {
        } finally {
            dispatch({isLoading: false})
        }
    }

    const renderIsRequiredHtml = (sectionCourse, coursesInfo) => {
        const isRequired = !!get(sectionCourse, ["isRequired"])
        if (sectionCourse.isAlternateCourse) {
            return null
        }
        return (
            <Checkbox
                disabled={!allowEdit}
                checked={isRequired}
                onChange={(event) => {
                    updateMandatory({
                        academicPlanSectionId: sectionCourse.academicPlanSectionId,
                        academicPlanId: sectionCourse.academicPlanCourseId,
                        academicPlanCourseId: sectionCourse.courseId,
                        alternateCourses: [],
                        studentProfileId: studentId,
                        isRequired: event.target.checked
                    })
                }}
            />
        )
    }

    const onCourseSearchChange = async (search, loadedOptions) => {
        try {
            const ignoreCourseIds = Object.values(coursesData)
                .filter((courseInfo: any) => courseInfo.isRequired || !!courseInfo.isAlternateCourse)
                .map((courseInfo: any) => courseInfo.courseId)
            const pageSize = 20
            const page = Math.ceil(loadedOptions.length / pageSize) + 1
            const {data: courses, total} = await courseService.getAutocompleteCourse({
                filter: {
                    search,
                    ignoreCourseIds
                },
                range: {
                    page,
                    pageSize
                }
            })
            return {
                options: courses,
                hasMore: loadedOptions.length < total
            }
        } catch (error) {
            return {
                options: [],
                hasMore: false
            }
        }
    }

    const onChangeAlternateCourse = (section, newValue = []) => {
        dispatch({isLoading: true})
        try {
            updateMandatory({
                academicPlanSectionId: section.academicPlanSectionId,
                studentProfileId: studentId,
                academicPlanCourseId: section.courseId,
                academicPlanId: section.academicPlanCourseId,
                alternateCourses: (newValue ?? []).map((item) => ({
                    academicPlanSectionId: section.academicPlanSectionId,
                    academicPlanCourseId: section.courseId,
                    alternateCourseId: item.courseId,
                    studentProfileId: studentId
                }))
            })
        } catch (err) {
        } finally {
            dispatch({isLoading: false})
        }
    }

    const renderAlternateCourses = (sectionCourse, coursesInfo) => {
        const data = get(sectionCourse, ["alternateCourses"], [])
        const isRequired = !!get(sectionCourse, ["isRequired"])
        if (!isRequired) {
            return null
        }
        return (
            <KlassappTableDropdownAsyncPaginate
                value={data}
                onChange={(newValue, actionMeta) => {
                    onChangeAlternateCourse(sectionCourse, newValue)
                }}
                isDisabled={!allowEdit}
                loadOptions={onCourseSearchChange}
                valueKey="courseId"
                isMulti
                isClearable={false}
            />
        )
    }

    const onClickRowItem = (row) => {}

    const onClickSortColumn = (field) => {
        let order = null
        if (isEmpty(orderField)) {
            order = "asc"
        } else {
            order = orderField.order === "asc" ? "desc" : "asc"
        }
        const sortedData = orderBy(data, field, order)
        props.dispatch({data: sortedData})
        props.onClickSortColumn(field)
    }

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: getPageTitle},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickRowItem", func: onClickRowItem},
            {key: "getMenuActions", func: getMenuActions},
            {key: "getTableHeaderActions", func: getTableHeaderActions}
        ])
        const canEdit = currentProfilePermissions.hasPermissions({
            staff: [Permissions.Staff.Academics.Registrar.Students.AcademicPlanning.Edit]
        })
        setAllowEdit(canEdit)
    }, [])

    useEffect(() => {
        getData()
    }, [allowEdit])

    useEffect(() => {
        getData()
    }, [page, pageSize])

    useEffect(() => {
        props.updateTableHeaderActions()
    }, [data])

    return (
        <>
            <KlassappTableHeader
                isShowAction={isShowTableHeaderAction}
                actions={tableHeaderActions}
                page={page}
                isShowAllRecords
                total={total}
                defaultPageSize={pageSize}
                onChangePage={props.onChangePage}
                onChangeRowPerPage={props.onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={props.onChangeFields}
                onChangeAllFields={props.onChangeAllFields}
                onDraggableColumn={props.onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={menuActions}
                isLoading={isLoading}
                allFields={allFields}
                orderField={orderField}
                isShowCheckedColumn
                onClickRowItem={onClickRowItem}
                onChangeFields={props.onChangeFields}
                onClickSortColumn={onClickSortColumn}
                onUpdateRowData={props.onUpdateRowData}
                onUpdateTableData={props.onUpdateTableData}
                onDraggableColumn={props.onDraggableColumn}
                onChangeAllFields={props.onChangeAllFields}
            />
            {changeStatusPopup.isVisible && (
                <ChangeCourseStatusPopup
                    isShow={changeStatusPopup.isVisible}
                    onClose={changeStatusPopup.close}
                    reloadView={reloadView}
                    studentProfileId={studentId}
                    student={student}
                    sectionCourse={activeCoursePopup}
                />
            )}
            {registerCoursePopup.isVisible && (
                <RegisterCoursePopup
                    isShow={registerCoursePopup.isVisible}
                    onClose={registerCoursePopup.close}
                    reloadView={reloadView}
                    studentProfileId={studentId}
                    sectionCourse={activeCoursePopup}
                />
            )}
        </>
    )
}

export default KlassappTableHOC(CourseSectionTable)
