import React from "react"
import {useTranslation} from "react-i18next"
import {handleError, openLinkInNewTab} from "helpers"
import {documentService} from "services"
import styles from "../../Documents.module.css"
import {UserDocument} from "types/user-documents"
import {BaseButton, BasePopup, SecondaryButton} from "components"
import {useMutation} from "@tanstack/react-query"
import {Col, Divider, Row} from "antd"
import PrintPrepareDocumentsTable from "./PrintPrepareDocumentsTable"

type PrintPreparePopupProps = {
    visible: boolean
    onClose: () => void
    documents: UserDocument[]
    moveItem: (dragIndex: number, hoverIndex: number) => void
}

const PrintPreparePopup = ({visible, onClose, documents, moveItem}: PrintPreparePopupProps) => {
    const {t} = useTranslation(["common"])

    const submitMutation = useMutation(
        async () => {
            const userDocumentIds = documents.map((item) => item.userDocumentId)
            if (!userDocumentIds.length) return []
            return await documentService.mergeDocuments({
                userDocumentIds
            })
        },
        {
            onSuccess: (fileUrls?: string[]) => {
                if (fileUrls?.length) {
                    fileUrls.forEach(openLinkInNewTab)
                }
                onClose()
            },
            onError: (error) => handleError(error)
        }
    )

    return (
        <BasePopup isShow={visible} onClose={onClose} width="70vw" leftIcon="FILE_ADD" leftIconColor="#fff">
            <div className={styles.wrap}>
                <div className={styles.popupTitle}>Prepare for print</div>
                <Divider />
                <div className={styles.popupHeading}>ORDER ITEMS FOR PRINT</div>
                <div className={styles.popupBody}>
                    Items will be printed in exact same order as presented here. Drag and drop to move items
                </div>
                <div className="mt-24">
                    <PrintPrepareDocumentsTable documents={documents} moveItem={moveItem} />
                </div>
                <Divider />
                <Row gutter={12} justify="end">
                    <Col>
                        <SecondaryButton onClick={onClose} title={t("common:action.cancel")} />
                    </Col>
                    <Col>
                        <BaseButton
                            onClick={submitMutation.mutate}
                            title={t("common:action.print")}
                            loading={submitMutation.isLoading}
                        />
                    </Col>
                </Row>
            </div>
        </BasePopup>
    )
}

export default PrintPreparePopup
