import {TotalHoursData} from "types/students"
import styles from "./WeeklyPerformance.module.css"
import {useMemo} from "react"

type PerformanceItem = {
    title: string
    emoji: string
    description: string
}

const PERFORMANCE_DICTIONARY: {[key: string]: PerformanceItem} = {
    trying: {
        title: "Room for Improvement",
        emoji: "😲",
        description: "Plan to make-up work!"
    },
    good: {
        title: "Keep Striving",
        emoji: "😎",
        description: "Consistency is key!"
    },
    perfect: {
        title: "You did it!",
        emoji: "🥳",
        description: "You're Crushing It!"
    }
}

type Props = {
    totalHoursData: TotalHoursData
}

export function WeeklyPerformance(props: Props) {
    const {totalHoursData} = props

    const data = useMemo(() => {
        if (!totalHoursData) return
        const hoursScheduled =
            totalHoursData.hoursScheduled > 0
                ? totalHoursData.hoursScheduled
                : totalHoursData.totalHoursCompleted + totalHoursData.missedAttendanceHours
        const totalMissedHours = Math.max(hoursScheduled - totalHoursData.totalHoursCompleted, 0)
        const missedPercentage = hoursScheduled > 0 ? (totalMissedHours / hoursScheduled) * 100 : 0
        let status: string = "trying"
        if (missedPercentage <= 20) {
            status = "good"
        }
        if (missedPercentage <= 2) {
            status = "perfect"
        }
        return {
            performance: PERFORMANCE_DICTIONARY[status],
            totalMissedHours,
            makeUpHours: totalHoursData.makeUpHours
        }
    }, [totalHoursData])

    if (!totalHoursData) return null

    return (
        <div className={styles.container}>
            <span className={styles.title}>{data.performance.title}</span>
            <span className={styles.emoji}>{data.performance.emoji}</span>
            <span className={styles.description}>{data.performance.description}</span>
            <div className={styles.moreDescWrap}>
                {data.totalMissedHours > 0 ? (
                    <div className={styles.descWrap}>
                        <span className={styles.moreDesc}>You've missed</span>
                        <span className={styles.descHighlight}>{data.totalMissedHours}</span>
                        <span className={styles.moreDesc}>hours</span>
                    </div>
                ) : (
                    <span className={styles.moreDesc}>You haven't missed lessons</span>
                )}
                {data.makeUpHours > 0 && (
                    <div className={styles.descWrap}>
                        <span className={styles.moreDesc}>Make up</span>
                        <span className={styles.descHighlight}>{data.makeUpHours}</span>
                        <span className={styles.moreDesc}>hours</span>
                    </div>
                )}
            </div>
        </div>
    )
}
