/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect, useMemo, useState} from "react"
import {
    academicCourseGradesService,
    attendanceService,
    courseService,
    degreeAuditService,
    instructionalService,
    studentServicesService
} from "services"
import {get, head} from "lodash"
import {formatDecimalWithoutRound, handleError} from "helpers"
import {BaseLoading} from "components"
import {TermInfo, TermStep, MyCourses, CourseInfo, CourseTab} from "./parts"
import {ProfileScore} from "../Common"
import {AcademicPlans} from "types/academicPlans"
import {Course} from "types/courses"
import {useStores} from "stores/useStores"
import {useModel, useVisible} from "hooks"
import {observer} from "mobx-react"
import {DegreeAuditProgressData} from "types/degreeAudit"
import {EditLdaPopup} from "../Common/ProfileScore/EditLdaPopup"
import {EditSapStatusPopup} from "../Common/ProfileScore/EditSapStatusPopup"
import {EditGpaPopup} from "../Common/ProfileScore/EditGpaPopup"
import {EditAttendancePopup} from "../Common/ProfileScore/EditAttendancePopup"

interface Props {
    studentId: number
    courseId: number
    scheduleId: number
    history?: any
    onChangeCourseId: (courseId: number, scheduleId: number) => void
    activeTerm: AcademicPlans.TermCourseStats
    setActiveTerm: (term: AcademicPlans.TermCourseStats) => void
}

const AcademicsStudentTerms = observer((props: Props) => {
    const {courseId, history, studentId, scheduleId, onChangeCourseId, activeTerm, setActiveTerm} = props
    const popupForEditingLda = useVisible()
    const popupForEditingSapStatus = useVisible()
    const popupForEditingGpa = useVisible()
    const popupForEditingAttendance = useVisible()
    const [isLoading, setIsLoading] = useState(false)
    const [terms, setTerms] = useState([])
    const [gpa, setGpa] = useState(0)
    const [academicStudentStatsId, setAcademicStudentStatsId] = useState()
    const [academicTermStatsId, setAcademicTermStatsId] = useState()
    const [termGpa, setTermGpa] = useState(0)
    const [cgpa, setCgpa] = useState(0)
    const [termSapStatus, setTermSapStatus] = useState()
    const [sapStatus, setSapStatus] = useState()
    const [termSapDate, setTermSapDate] = useState()
    const [sapDate, setSapDate] = useState()
    const model = useModel()
    const [lda, setLda] = useState()
    const [courseInfo, setCourseInfo] = useState<Course.InstructionalCourse>()
    const [progressData, setProgressData] = useState<DegreeAuditProgressData>({
        completed: 0,
        distEdHours: 0,
        attempted: 0,
        appliedToDegree: 0,
        degreeRequirement: 0,
        totalTransferApplied: 0
    })

    let studentProfileIds = [studentId]

    const {
        academics: {instructional}
    } = useStores()

    useEffect(() => {
        getTermCourseStats()
    }, [])

    useEffect(() => {
        if (courseId) {
            instructional.getCurrentCourse(courseId)
        }
    }, [instructional, courseId])

    const getProgressData = async (termIds: number[], courseId?: number) => {
        let studentProfileId = studentId
        const response = await attendanceService.getCourseStatistics({
            studentProfileIds: [studentProfileId],
            termIds,
            courseId
        })
        const data = response.data
        const totalHours = data.totalHours ?? 0
        const attempted = data.progress.reduce((total, progress) => (total += progress?.total ?? 0), 0)
        const completed = data.progress.reduce((total, progress) => (total += progress?.completed ?? 0), 0)
        setProgressData({
            distEdHours: data.distEdHours,
            attempted,
            completed,
            appliedToDegree: totalHours
        })
    }

    useEffect(() => {
        if (courseId) {
            const termIds = [activeTerm?.termId]
            instructional.getTodayLessons({nextLessons: true, termIds})
            activeTerm?.id && getProgressData(termIds, courseId)
            instructional.getAllActionNeededLessonsAttendanceByCourse(courseId, studentProfileIds, termIds)
            instructional.getAttendanceRatingByCourse(courseId, studentProfileIds, termIds)
            instructional.getAllLessonsAttendanceByCourse(courseId, [], termIds)
            instructional.getCourseStudentsAttendanceStats(courseId, termIds)
            instructional.getMissedAttendanceStats({
                courseIds: [courseId],
                termIds,
                studentProfileIds: studentProfileIds
            })
        } else if (activeTerm?.termId) {
            const termIds = [activeTerm?.termId]
            getProgressData(termIds)
            instructional.getAttendanceRatingByCourse(undefined, studentProfileIds, termIds)
            instructional.getMissedAttendanceStats({
                courseIds: undefined,
                termIds,
                studentProfileIds: studentProfileIds
            })
        }
    }, [instructional, activeTerm, studentId])

    useEffect(() => {
        if (courseId) {
            getCourseInfo()
            getAttendanceStats()
        } else if (activeTerm?.termId) {
            getAttendanceStats()
            getGpaAndLdaInfo()
            setActiveTerm(activeTerm)
        }
    }, [courseId, activeTerm, studentId, scheduleId])

    useEffect(() => {
        if (courseInfo) {
            getGpaAndLdaInfo()
        }
    }, [courseInfo])

    const getCourseInfo = async () => {
        try {
            setIsLoading(true)
            const params: any = {
                range: {page: 1, pageSize: 1},
                filter: {isPracticalOrClinicalServices: false, courseIds: [courseId]}
            }
            if (studentId) {
                params.filter.studentProfileIds = [studentId]
            }
            if (activeTerm?.termId) {
                params.filter.termIds = [activeTerm.termId]
            }
            if (scheduleId) {
                params.filter.scheduleIds = [scheduleId]
            }
            const {data} = await instructionalService.getInstructionalMyCourse(params)
            setCourseInfo(head(data))
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    async function getTermCourseStats() {
        try {
            setIsLoading(true)
            const {terms} = await studentServicesService.getTermCourseStats({profileId: studentId})
            const termsData = terms.map((term) => ({
                ...term,
                name: term.title
            }))
            setTerms(termsData)
            const termInfo: AcademicPlans.TermCourseStats = head(termsData)
            if (termInfo) {
                setActiveTerm(termInfo)
            }
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    const CourseInfoComponent = useMemo(() => {
        if (courseInfo) {
            return (
                <CourseInfo
                    gpa={gpa}
                    studentProfileId={studentId}
                    type={courseInfo?.type}
                    isAcademicStudentTerm
                    termInfo={activeTerm}
                    codeCourse={courseInfo?.courseCode}
                    courseName={courseInfo?.courseName}
                    instructor={courseInfo?.instructors}
                    firstDayOfCourse={courseInfo?.firstDayOfCourse}
                    lastDayOfCourse={courseInfo?.lastDayOfCourse}
                    isShowAcademicAdvisorPopup={false}
                    progressData={progressData}
                    attendanceRating={instructional.attendanceRatingByCourse.value?.percentage ?? 0}
                />
            )
        }
        return null
    }, [courseInfo, gpa, progressData, instructional.attendanceRatingByCourse, studentId])

    const getAttendanceStats = () => {
        if (courseId) {
            const termIds = [activeTerm.termId]
            instructional.getAttendanceRatingByCourse(courseId, studentProfileIds, termIds)
            getGpaAndLdaInfo()
        } else if (activeTerm?.termId) {
            const termIds = [activeTerm.termId]
            getGpaAndLdaInfo()
            instructional.getAttendanceRatingByCourse(undefined, studentProfileIds, termIds)
        }
    }

    const getGpaAndLdaInfo = async () => {
        const params: any = {
            termId: activeTerm.termId
        }
        if (courseId) {
            params.courseId = courseId
            if (courseInfo?.scheduleId) {
                params.scheduleId = courseInfo.scheduleId
            }
        }
        if (courseInfo?.scheduleId) {
            params.scheduleId = courseInfo.scheduleId
        }
        const profileId = get(model, "user.profiles[0].id")
        if (studentId) {
            params.studentProfileId = studentId
        } else if (!model.isStaffOrAdmin() && profileId) {
            params.studentProfileId = profileId
        }
        const {
            termGpa,
            gpa,
            cgpa,
            lda,
            termSapStatus,
            sapStatus,
            termSapDate,
            sapDate,
            academicStudentStatsId,
            academicTermStatsId
        } = await academicCourseGradesService.getCourseGradeStudentStats(params)
        setLda(lda)
        setAcademicStudentStatsId(academicStudentStatsId)
        setAcademicTermStatsId(academicTermStatsId)
        setTermGpa(typeof termGpa === "number" ? formatDecimalWithoutRound(termGpa) : termGpa)
        setGpa(typeof gpa === "number" ? formatDecimalWithoutRound(gpa) : gpa)
        setCgpa(typeof cgpa === "number" ? formatDecimalWithoutRound(cgpa) : cgpa)
        setSapStatus(sapStatus)
        setTermSapStatus(termSapStatus)
        setTermSapDate(termSapDate)
        setSapDate(sapDate)
    }

    const onRefreshTermSteps = async () => {
        try {
            setIsLoading(true)
            const {terms} = await studentServicesService.getTermCourseStats({profileId: studentId})
            const termsData = terms.map((term) => ({
                ...term,
                name: term.title
            }))
            setTerms(termsData)
        } catch (error) {
            handleError(error)
        } finally {
            setIsLoading(false)
        }
    }

    if (isLoading) {
        return <BaseLoading isShow />
    }

    if (!activeTerm) {
        return <div>No Information</div>
    }

    return (
        <div>
            {courseId ? (
                <>
                    {CourseInfoComponent}
                    <ProfileScore
                        lastDayAttendance={lda}
                        gpa={gpa}
                        cgpa={cgpa}
                        sapStatus={sapStatus}
                        isEditableGpa={model.isStaffOrAdmin()}
                        onEditGpa={popupForEditingGpa.open}
                        sapDate={sapDate}
                        isShowSapStatus
                        isEditableLda={model.isStaffOrAdmin()}
                        isEditableSapStatus={model.isStaffOrAdmin()}
                        onClickEditLda={popupForEditingLda.open}
                        onEditAttendance={popupForEditingAttendance.open}
                        isEditableAttendance={model.isStaffOrAdmin()}
                        onEditSapStatus={popupForEditingSapStatus.open}
                        missedHours={instructional.missedAttendanceStats?.value?.missedHours}
                        attendanceRating={instructional.attendanceRatingByCourse.value?.percentage ?? 0}
                    />
                    <CourseTab
                        getAttendanceStats={getAttendanceStats}
                        courseInfo={courseInfo}
                        scheduleIds={scheduleId ? [scheduleId] : []}
                        studentProfileIds={studentProfileIds}
                        courseIds={[courseId]}
                        termIds={activeTerm?.termId ? [activeTerm?.termId] : []}
                    />
                </>
            ) : (
                <>
                    <TermStep
                        {...props}
                        onChangeActiveTerm={(term: AcademicPlans.TermCourseStats) => {
                            setActiveTerm(term)
                        }}
                        terms={terms}
                    />
                    <TermInfo
                        {...props}
                        progressData={progressData}
                        attendanceRating={instructional.attendanceRatingByCourse.value?.percentage ?? 0}
                        gpa={gpa}
                        termIds={activeTerm?.termId ? [activeTerm?.termId] : []}
                        setGpa={setGpa}
                        activeTerm={activeTerm}
                    />
                    <ProfileScore
                        cgpa={cgpa}
                        sapStatus={termSapStatus}
                        sapDate={termSapDate}
                        isEditableGpa={model.isStaffOrAdmin()}
                        isShowSapStatus
                        onEditGpa={popupForEditingGpa.open}
                        onEditAttendance={popupForEditingAttendance.open}
                        isEditableAttendance={model.isStaffOrAdmin()}
                        isEditableSapStatus={model.isStaffOrAdmin()}
                        onEditSapStatus={popupForEditingSapStatus.open}
                        gpa={termGpa}
                        lastDayAttendance={lda}
                        missedHours={instructional.missedAttendanceStats?.value?.missedHours}
                        attendanceRating={instructional.attendanceRatingByCourse.value?.percentage ?? 0}
                    />
                    <MyCourses
                        termIds={activeTerm?.termId ? [activeTerm?.termId] : []}
                        history={history}
                        onClickCourseId={onChangeCourseId}
                        studentId={studentId}
                    />
                </>
            )}
            <EditLdaPopup
                isShow={popupForEditingLda.isVisible}
                onClose={popupForEditingLda.close}
                courseId={courseId}
                studentProfileId={studentId}
                lda={lda}
                termId={activeTerm?.termId}
                onRefreshLda={getGpaAndLdaInfo}
                scheduleId={courseInfo?.scheduleId}
            />
            <EditSapStatusPopup
                isShow={popupForEditingSapStatus.isVisible}
                onClose={popupForEditingSapStatus.close}
                courseId={courseId}
                studentProfileId={studentId}
                sapStatus={!courseId ? termSapStatus : sapStatus}
                termId={activeTerm?.termId}
                sapDate={!courseId ? termSapDate : sapDate}
                onRefreshLda={getGpaAndLdaInfo}
                scheduleId={courseInfo?.scheduleId}
            />
            <EditGpaPopup
                isShow={popupForEditingGpa.isVisible}
                onClose={popupForEditingGpa.close}
                courseId={courseId}
                academicStudentStatsId={academicStudentStatsId}
                academicTermStatsId={academicTermStatsId}
                studentProfileId={studentId}
                gpa={courseId ? gpa : termGpa}
                termId={activeTerm?.termId}
                sapDate={!courseId ? termSapDate : sapDate}
                onRefreshGpa={() => {
                    getGpaAndLdaInfo()
                    return onRefreshTermSteps()
                }}
                scheduleId={courseInfo?.scheduleId}
            />
            <EditAttendancePopup
                isShow={popupForEditingAttendance.isVisible}
                onClose={popupForEditingAttendance.close}
                courseId={courseId}
                academicStudentStatsId={academicStudentStatsId}
                academicTermStatsId={academicTermStatsId}
                studentProfileId={studentId}
                termId={activeTerm?.termId}
                percentage={instructional.attendanceRatingByCourse.value?.percentage}
                onRefresh={getAttendanceStats}
                scheduleId={courseInfo?.scheduleId}
            />
        </div>
    )
})

export {AcademicsStudentTerms}
