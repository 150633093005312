/* eslint-disable react-hooks/exhaustive-deps */
import styles from "./Compose.module.css"
import {ReactComponent as AttachmentIcon} from "./assets/attachment.svg"
import {ReactComponent as FolderIcon} from "./assets/folder.svg"
import {ReactComponent as EmojiiIcon} from "./assets/emojii.svg"
import {ReactComponent as EditIcon} from "./assets/edit.svg"
import {ReactComponent as CloseIcon} from "./assets/close.svg"
import {ReactComponent as FileIcon} from "./assets/file.svg"
import {BaseButton, BaseLoading, Icon} from "components"
import {useCallback, useContext, useEffect, useMemo, useRef, useState} from "react"
import cx from "classnames"
import {Checkbox, Tooltip} from "antd"
import inputStyles from "./Input.module.css"
import {MentionsInput, Mention} from "react-mentions"
import {handleErrorChat, toastError} from "helpers"
import {UserAvatar} from "../UserAvatar"
import {CommunicationContext} from "context/CommunicationContext"
import {Communication} from "types/communication"
import {ChannelSelect} from "../ChannelSelect"
import {ReplyMessage} from "../ReplyMessage"
import {useModel, useVisible} from "hooks"
import {EmojiPicker} from "components/Messenger/Chat/parts/EmojiPicker"
import {MediaLibraryPopup, PollPopupV2, TemplateSelection} from "./parts"
import {ChatRoomContext} from "context/ChatRoomContext"
import {chatServiceV2, notificationService, userService} from "services"
import {uploadUsingPreSignedUrl} from "helpers/chat"
import ChatWsV2 from "sections/NewCommunication/chatWsV2"
import {Descendant} from "slate"
import {INITIAL_RTE_CONTENT, isEmptyContent} from "components/RichTextEditor/slate-custom-types"
import RichTextEditor from "components/RichTextEditor"
import useThrottle from "sections/communication/hooks/useThrottle"
import {BaseDatePicker, BaseTimePicker} from "components/DateTimePicker"
import moment from "moment"
import momentTz from "moment-timezone"
import {MEDIA_LIBRARY_ICONS} from "components/MediaLibrarySelect/MediaLibrarySelect"

type Props = {
    editMessage?: Communication.Message
    replyMessage?: Communication.ReplyMessage
    onlyContent?: boolean
    userList?: Communication.RoomUser[]
    forThread?: Communication.Message
    onSuccess?: (message?: Communication.Message | Communication.SupportRoom) => void
    emojiPickerClassName?: string
    onCancelEdit?: () => void
    isPollEnabled?: boolean
    pollRoomId?: number
    isNewSupportRequest?: boolean
}

export function Compose(props: Props) {
    const {
        editMessage: initialEditMessage,
        replyMessage: initialReplyMessage,
        onlyContent = false,
        userList,
        forThread,
        onSuccess,
        emojiPickerClassName,
        onCancelEdit,
        isPollEnabled,
        pollRoomId,
        isNewSupportRequest
    } = props
    const [msgText, setMsgText] = useState<string>("")
    const [msgTitle, setMsgTitle] = useState<string>("")
    const [sendWithEmail, setSendWithEmail] = useState<boolean>(false)
    const [sendWithSms, setSendWithSms] = useState<boolean>(false)
    const [withActivity, setWithActivity] = useState<boolean>(false)
    const [withStaffsNotification, setWithStaffsNotification] = useState<boolean>(false)
    const [isSending, setIsSending] = useState(false)
    const [replyMessage, setReplyMessage] = useState<Communication.ReplyMessage | undefined>()
    const [editMessage, setEditMessage] = useState<Communication.Message | undefined>()
    const [attachedFile, setAttachedFile] = useState<Communication.AttachedFile | undefined>()
    const attachFileRef = useRef(null)
    const pollPopup = useVisible(false)
    const emojiPopup = useVisible(false)
    const libraryPopup = useVisible(false)
    const [mediaLibraryItem, setMediaLibraryItem] = useState<any>()
    const {activeRoom, activeGroup, moveRoomToFirst} = useContext(CommunicationContext)
    const {activePageId} = useContext(ChatRoomContext)
    const [scheduledChannel, setScheduledChannel] = useState<Communication.ChannelRoom>()

    const model = useModel()
    const isStaffOrAdmin = model.isStaffOrAdmin()
    const userDateFormat = model.getUserDateFormat()
    const userTimeFormat = model.getUserTimeFormat()
    const [canSendSms, setCanSendSms] = useState<boolean>(false)
    const [canCreateActivity, setCanCreateActivity] = useState<boolean>(false)
    const [selectedTemplate, setSelectedTemplate] = useState(null)
    const [descendants, setDescendants] = useState<Descendant[]>(INITIAL_RTE_CONTENT)
    const [subject, setSubject] = useState<string>("")
    const [scheduledDate, setScheduledDate] = useState<any>()
    const [scheduledTime, setScheduledTime] = useState<any>()
    const [isFetchingTemplate, setIsFetchingTemplate] = useState(false)

    const throttleText = useThrottle(msgText, 3000)

    useEffect(() => {
        resetForm()
    }, [activeRoom.roomId, activePageId])

    useEffect(() => {
        checkSendAsSms()
        checkCreateActivity()
    }, [activeRoom.roomId, activeGroup?.id])

    const isScheduledRoom = useMemo(() => {
        return activeRoom.type === Communication.RoomTypes.Scheduled
    }, [activeRoom.type])

    const isDepartmentRoom = useMemo(() => {
        return [Communication.RoomTypes.Department, Communication.RoomTypes.MultiDepartmental].includes(activeRoom.type)
    }, [activeRoom.type])

    const isChannelOrScheduledRoom = useMemo(() => {
        return [Communication.RoomTypes.Channel, Communication.RoomTypes.Scheduled].includes(activeRoom.type)
    }, [activeRoom.type])

    const isDirectOrDepartmentRoom = useMemo(() => {
        return [Communication.RoomTypes.DirectChat, Communication.RoomTypes.Department].includes(activeRoom.type)
    }, [activeRoom.type])

    const isSupportRoom = useMemo(() => {
        return [Communication.RoomTypes.OpenedSupportHub, Communication.RoomTypes.ClosedSupportHub].includes(
            activeRoom.type
        )
    }, [activeRoom.type])

    const showSendOptions = useMemo(() => {
        return (
            isStaffOrAdmin &&
            ([
                Communication.RoomTypes.Department,
                Communication.RoomTypes.DirectMessage,
                Communication.RoomTypes.MultiDepartmental
            ].includes(activeRoom.type) ||
                (activeRoom.type === Communication.RoomTypes.DirectChat && !activeRoom.isGroup))
        )
    }, [isStaffOrAdmin, activeRoom])

    useEffect(() => {
        if (initialEditMessage) {
            setEditMessage(initialEditMessage)
            setMsgText(initialEditMessage.content || "")
            setMsgTitle(initialEditMessage.title || "")
            if (initialEditMessage.replyTo) {
                setReplyMessage(initialEditMessage.replyTo)
            }
            if (isScheduledRoom) {
                setScheduledChannel(initialEditMessage.room)
                setScheduledDate(moment(initialEditMessage.scheduledAt))
                setScheduledTime(moment(initialEditMessage.scheduledAt))
            }

            setSendWithEmail(false)
            setSendWithSms(false)
        }
    }, [initialEditMessage, isScheduledRoom])

    useEffect(() => {
        if (initialReplyMessage) {
            setReplyMessage(initialReplyMessage)
            setSendWithEmail(false)
            setSendWithSms(false)
        }
    }, [initialReplyMessage])

    useEffect(() => {
        ;(async () => {
            const isTyping = !!throttleText
            if (isTyping && isDirectOrDepartmentRoom) {
                ChatWsV2.sendPacket({command: Communication.SocketCommand.Typing, payload: {roomId: activeRoom.roomId}})
            }
        })()
    }, [throttleText, activeRoom.roomId, isDirectOrDepartmentRoom])

    const resetForm = useCallback(() => {
        setSendWithEmail(false)
        setSendWithSms(false)
        setWithActivity(false)
        setIsSending(false)
        setReplyMessage(undefined)
        setEditMessage(undefined)
        setAttachedFile(undefined)
        setMediaLibraryItem(undefined)
        setSubject("")
        setSelectedTemplate(null)
        setMsgText("")
        setMsgTitle("")

        setDescendants(INITIAL_RTE_CONTENT)

        // scheduled
        setScheduledChannel(null)
        setScheduledDate(undefined)
        setScheduledTime(undefined)
    }, [])

    const onToggleSendAsEmail = () => {
        setSendWithEmail((prev) => {
            handleMessageContentChange(!sendWithSms && !prev, prev)

            setReplyMessage(undefined)
            setEditMessage(undefined)
            setAttachedFile(undefined)
            setMediaLibraryItem(undefined)
            return !prev
        })
    }

    const onToggleSendAsSms = () => {
        setSendWithSms((prev) => {
            handleMessageContentChange(!sendWithEmail && !prev, prev)

            setReplyMessage(undefined)
            setEditMessage(undefined)
            setAttachedFile(undefined)
            setMediaLibraryItem(undefined)
            return !prev
        })
    }

    const handleMessageContentChange = (convertToHtmlText: boolean, clearText: boolean) => {
        if (convertToHtmlText) {
            const _descendants: Descendant[] = [
                {
                    type: "paragraph",
                    children: [{text: msgText}]
                }
            ]
            setDescendants(_descendants)
        } else {
            if (clearText) {
                setMsgText("")
            }
        }
    }

    const checkSendAsSms = async () => {
        if (isDepartmentRoom) {
            const departmentRoom = activeRoom as Communication.DepartmentRoom
            const departmentId = departmentRoom.departmentId ?? 0
            const {data: users} = await userService.searchUsers({
                filters: {
                    type: "staff",
                    departmentPermissionMatch: `${departmentId}.0.chat.view`
                },
                range: {
                    page: 1,
                    pageSize: 1000
                }
            })
            const userWithDepartmentChatPermission = users.map((u) => u.userId)
            userWithDepartmentChatPermission.push(model.user.id)
            await model.getUsers(userWithDepartmentChatPermission)
            const canSendSms = !!model.smsDepartments.find((id) => id === departmentId)
            setCanSendSms(canSendSms)
        } else {
            setCanSendSms(model.canSendDirectSms)
        }
    }

    const checkCreateActivity = async () => {
        if (isDepartmentRoom) {
            const departmentId = activeGroup?.id ?? activeRoom["departmentId"] ?? 0
            const chatActivityId = model.departmentsChats.find(
                (d) => d.departmentId === departmentId && d.chatActivityId
            )
            setCanCreateActivity(!!chatActivityId)
        } else {
            setCanCreateActivity(false)
        }
    }

    const selectedUserId = useMemo(() => {
        if (
            [Communication.RoomTypes.DirectMessage, Communication.RoomTypes.MultiDepartmental].includes(activeRoom.type)
        ) {
            return null
        }
        let users = (userList || []).filter((x) => !x.isMe)
        if (activeRoom.type === Communication.RoomTypes.Department) {
            users = users.filter((x) => x.isDepartmentChatUser)
        }
        return users.length ? users[0].userId : null
    }, [userList, activeRoom])

    const isContentAvailable = useMemo(() => {
        return (msgText && msgText !== "") || attachedFile || mediaLibraryItem
    }, [msgText, attachedFile, mediaLibraryItem])

    const isSendEmailAvailable = useMemo(() => {
        if (sendWithEmail) return !!subject && subject !== "" && !isEmptyContent(descendants)
        return true
    }, [sendWithEmail, descendants, subject])

    const isSendSmsAvailable = useMemo(() => {
        if (sendWithSms) return !isEmptyContent(descendants)
        return true
    }, [sendWithSms, descendants])

    const isEditting = useMemo(() => {
        return !!editMessage
    }, [editMessage])

    const isScheduleAvailable = useMemo(() => {
        if (isScheduledRoom) {
            if (scheduledChannel && scheduledDate && scheduledTime) {
                const _date = moment(
                    `${moment(scheduledDate).format("MM/DD/YYYY")} ${moment(scheduledTime).format("HH:mm")}`
                )
                return _date.isAfter(moment())
            }
            return false
        }
        return true
    }, [isScheduledRoom, scheduledChannel, scheduledDate, scheduledTime])

    const isNewSupportAvailable = useMemo(() => {
        if (isNewSupportRequest) {
            return isContentAvailable && !!msgTitle
        }
        return true
    }, [msgTitle, isNewSupportRequest, isContentAvailable])

    const isSendAvailable = useMemo(() => {
        return (
            isContentAvailable &&
            isSendEmailAvailable &&
            isSendSmsAvailable &&
            isScheduleAvailable &&
            isNewSupportAvailable
        )
    }, [isContentAvailable, isScheduleAvailable, isSendEmailAvailable, isSendSmsAvailable, isNewSupportAvailable])

    const onPressEnterSend = (event) => {
        if (event.keyCode === 13 && !event.shiftKey && !isChannelOrScheduledRoom) {
            const chatSettings = model.chatOptions[Communication.ChatOptions.Settings] as Communication.ChatSettings
            const isEnterWillSendMessage = chatSettings
                ? chatSettings.returnKey === Communication.ReturnKey.NEW_MESSAGE
                : true
            if (isEnterWillSendMessage) {
                event.preventDefault()
                verifyBeforeSend()
            }
        }
    }

    const verifyBeforeSend = () => {
        if (!isSending && isSendAvailable) {
            if (isChannelOrScheduledRoom && !forThread) {
                if (isScheduledRoom && !scheduledChannel) {
                    toastError("Please select channel to post")
                    return
                }
                onSubmitThread()
                return
            }
            if (activeRoom.type === Communication.RoomTypes.DirectMessage) {
                onSendDirectMessage()
                return
            }
            if (activeRoom.type === Communication.RoomTypes.MultiDepartmental) {
                onSendMultiDepartmentalMessage()
                return
            }
            if (isNewSupportRequest) {
                if (!msgTitle) {
                    toastError("Title is required")
                    return
                }
                onSubmitSupport()
                return
            }
            onSendMessage()
        }
    }

    const onClickSend = () => {
        verifyBeforeSend()
    }

    const buildAttachedFilePayload = async () => {
        let _attachedFile = {...attachedFile},
            mediaLibraryId: any = undefined
        if (attachedFile && attachedFile.file) {
            const fileExtension = attachedFile.file.name.split(".").pop()
            const {url, urlForUploading} = await chatServiceV2.getPreSignedUrl({fileExtension})
            await uploadUsingPreSignedUrl(urlForUploading, attachedFile.file)
            _attachedFile.url = url
        }

        if (mediaLibraryItem) {
            let url
            switch (mediaLibraryItem.type) {
                case "image":
                    url = mediaLibraryItem.details.url
                    break
                case "video":
                    url = mediaLibraryItem.details.videoUrl
                    break
                default:
                    url = mediaLibraryItem.details.url
                    break
            }
            _attachedFile = {
                url,
                filename: mediaLibraryItem.title,
                type: mediaLibraryItem.type,
                mimetype: mediaLibraryItem.type,
                fileSize: 0
            }
            mediaLibraryId = mediaLibraryItem.itemId
        }
        return {_attachedFile, mediaLibraryId}
    }

    const onSendMessage = async () => {
        try {
            setIsSending(true)
            let payload: any = {
                roomId: forThread ? forThread.messageId : activeRoom.roomId,
                content: msgText,
                type: "message",
                withSms: sendWithSms,
                withEmail: sendWithEmail,
                jsonData: {
                    withStaffsNotification,
                    withActivity: canCreateActivity && withActivity
                }
            }

            const {_attachedFile, mediaLibraryId} = await buildAttachedFilePayload()
            if (mediaLibraryId) {
                payload.jsonData = {...payload.jsonData, mediaLibraryId}
            }
            if (_attachedFile?.url) {
                const {file, poster, ...rest} = _attachedFile
                payload.file = rest
            }

            if (isEditting && editMessage) {
                payload.messageId = editMessage.messageId
            }

            if (replyMessage) {
                payload.replyToMessageId = replyMessage.messageId
            }

            if (sendWithEmail) {
                payload.jsonData = {
                    email: {
                        subject: `${model.clientSetting?.slug.toUpperCase()}: ${subject.trim()}`,
                        html: msgText,
                        editorType:
                            selectedTemplate?.editorType === Communication.EditorType.Html
                                ? Communication.EditorType.Html
                                : Communication.EditorType.Text
                    }
                }
            }

            ChatWsV2.sendPacket({command: "newMessage", payload})
            resetForm()
            onSuccess?.()
            moveRoomToFirst(Communication.RoomGroupId.Direct, activeRoom.roomId as string)
        } catch (e: any) {
            handleErrorChat(e)
        } finally {
            setIsSending(false)
        }
    }

    const onSubmitThread = async () => {
        try {
            setIsSending(true)
            let payload: any = {
                description: msgText,
                name: msgTitle,
                withEmail: sendWithEmail,
                withSms: sendWithSms,
                jsonData: {}
            }

            const {_attachedFile, mediaLibraryId} = await buildAttachedFilePayload()
            if (mediaLibraryId) {
                payload.jsonData = {...payload.jsonData, mediaLibraryId}
            }
            if (_attachedFile?.url) {
                const {file, poster, ...rest} = _attachedFile
                payload.file = rest
            }

            if (isScheduledRoom) {
                payload.scheduledAt = `${moment(scheduledDate).format("YYYY-MM-DD")} ${moment(scheduledTime).format(
                    "HH:mm:ss"
                )}`
                payload.timezone = momentTz.tz.guess()
            }

            let updatedMessage: Communication.Message
            if (isEditting && editMessage) {
                payload.roomId = editMessage.messageId
                updatedMessage = await chatServiceV2.editThread(payload)
            } else {
                payload.parentRoomId = isScheduledRoom ? scheduledChannel.roomId : activeRoom.roomId
                await chatServiceV2.createThread(payload)
            }
            resetForm()
            onSuccess?.(updatedMessage)
        } catch (e: any) {
            handleErrorChat(e)
        } finally {
            setIsSending(false)
        }
    }

    const onSendDirectMessage = async () => {
        try {
            setIsSending(true)
            let payload: any = {
                filter: {
                    userIds: (activeRoom as Communication.DirectMessageRoom).directMessageUserIds
                },
                message: msgText,
                options: {},
                targets: ["chatDirectMessage"]
            }

            let chatDirectMessage: any = {
                content: msgText,
                senderId: model.user.id,
                type: "message",
                jsonData: {
                    source: {}
                }
            }

            const {_attachedFile, mediaLibraryId} = await buildAttachedFilePayload()
            if (mediaLibraryId) {
                chatDirectMessage.jsonData = {
                    ...chatDirectMessage.jsonData,
                    mediaLibraryId
                }
            }
            if (_attachedFile?.url) {
                const {file, poster, ...rest} = _attachedFile
                chatDirectMessage.file = rest
            }

            if (sendWithSms) {
                chatDirectMessage.withSms = true
                chatDirectMessage.jsonData = {
                    ...chatDirectMessage.jsonData,
                    serialize: true
                }
            }

            if (sendWithEmail) {
                chatDirectMessage.withEmail = true
                chatDirectMessage.jsonData = {
                    ...chatDirectMessage.jsonData,
                    serialize: true,
                    email: {
                        subject: `${model.clientSetting?.slug.toUpperCase()}: ${subject.trim()}`,
                        html: msgText,
                        editorType:
                            selectedTemplate?.editorType === Communication.EditorType.Html
                                ? Communication.EditorType.Html
                                : Communication.EditorType.Text
                    }
                }
            }
            payload.options = {chatDirectMessage}

            await notificationService.send(payload)
            onSuccess?.()
        } catch (e: any) {
            handleErrorChat(e)
        } finally {
            setIsSending(false)
        }
    }

    const onSendMultiDepartmentalMessage = async () => {
        try {
            setIsSending(true)
            let payload: any = {
                roomIds: (activeRoom as Communication.MultiDepartmentalMessageRoom).departmentCampusRoomIds,
                content: msgText,
                type: "message",
                withEmail: sendWithEmail,
                withSms: sendWithSms,
                jsonData: {
                    withStaffsNotification,
                    withActivity: canCreateActivity && withActivity,
                    serialize: true
                }
            }

            const {_attachedFile, mediaLibraryId} = await buildAttachedFilePayload()
            if (mediaLibraryId) {
                payload.jsonData = {
                    ...payload.jsonData,
                    mediaLibraryId
                }
            }
            if (_attachedFile?.url) {
                const {file, poster, ...rest} = _attachedFile
                payload.file = rest
            }

            if (sendWithEmail) {
                payload.jsonData = {
                    ...payload.jsonData,
                    email: {
                        subject: `${model.clientSetting?.slug.toUpperCase()}: ${subject.trim()}`,
                        html: msgText,
                        editorType:
                            selectedTemplate?.editorType === Communication.EditorType.Html
                                ? Communication.EditorType.Html
                                : Communication.EditorType.Text
                    }
                }
            }

            await chatServiceV2.sendMessageToMultipleRooms(payload)
            onSuccess?.()
        } catch (e: any) {
            handleErrorChat(e)
        } finally {
            setIsSending(false)
        }
    }

    const onSubmitSupport = async () => {
        try {
            setIsSending(true)
            let payload: any = {
                description: msgText,
                name: msgTitle
            }

            const {_attachedFile} = await buildAttachedFilePayload()
            if (_attachedFile?.url) {
                const {file, poster, ...rest} = _attachedFile
                payload.file = rest
            }

            const room = await chatServiceV2.createSupport(payload)
            resetForm()
            onSuccess?.(room)
        } catch (e: any) {
            handleErrorChat(e)
        } finally {
            setIsSending(false)
        }
    }

    const onChangeText = (event) => {
        setMsgText(event.target.value)
    }

    const cancelReply = () => {
        setReplyMessage(undefined)
    }

    const onSelectFile = (e) => {
        const file: File = e.target.files[0]
        if (file) {
            setAttachedFile({
                url: URL.createObjectURL(file),
                mimetype: file.type,
                filename: file.name,
                fileSize: file.size,
                file,
                type: ["image", "video"].includes(file.type.split("/")[0])
                    ? (file.type.split("/")[0] as Communication.AttachedFileType)
                    : Communication.AttachedFileType.File
            })
            setMediaLibraryItem(undefined)
        }
    }

    const onClickFile = (e) => {
        e.target.value = ""
    }

    const onSelectEmoji = (emoji) => {
        setMsgText((pre) => `${pre || ""}${emoji.native}`)
    }

    const onSelectMediaLibraryItem = (_mediaLibraryItem) => {
        setMediaLibraryItem(_mediaLibraryItem)
        setAttachedFile(undefined)
    }

    const renderChannelView = () => {
        if (onlyContent) {
            if (isEditting && editMessage) {
                return (
                    <div className={styles.actionWrap}>
                        <div className={styles.optionWrap}>
                            <EditIcon className={styles.headingIcon} />
                            <span className={styles.headingTitle}>Edit {forThread ? "comment" : "message"}</span>
                        </div>
                        <div className={styles.option} onClick={resetForm}>
                            <CloseIcon className={styles.headingIcon} />
                        </div>
                    </div>
                )
            }
            return null
        }
        if (![Communication.RoomTypes.Channel, Communication.RoomTypes.Scheduled].includes(activeRoom.type)) {
            return null
        }
        const title = `${isEditting ? "Edit" : "Compose"} a ${isScheduledRoom ? "scheduled" : ""} thread`

        return (
            <>
                <div className={styles.actionWrap}>
                    <div className={styles.optionWrap}>
                        <EditIcon className={styles.headingIcon} />
                        <span className={styles.headingTitle}>{title}</span>
                    </div>
                    {isEditting && (
                        <div
                            className={styles.option}
                            onClick={() => {
                                resetForm()
                                if (isEditting) onCancelEdit?.()
                            }}>
                            <CloseIcon className={styles.headingIcon} />
                        </div>
                    )}
                </div>
                {isScheduledRoom && (
                    <div className={styles.channelInputWrap}>
                        <ChannelSelect
                            placeholder="Select channel to post into"
                            value={scheduledChannel}
                            onChange={setScheduledChannel}
                            readOnly={isEditting}
                        />
                    </div>
                )}
                <div className={styles.titleInputWrap}>
                    <input
                        className={styles.titleInput}
                        placeholder="Title (Optional)"
                        onChange={(e) => setMsgTitle(e.target.value)}
                        value={msgTitle}
                    />
                </div>
            </>
        )
    }

    const renderNewSupportView = () => {
        return (
            <>
                <div className={styles.actionWrap}>
                    <div className={styles.optionWrap}>
                        <EditIcon className={styles.headingIcon} />
                        <span className={styles.headingTitle}>New Support Request</span>
                    </div>
                    <div className={styles.option} onClick={resetForm}>
                        <CloseIcon className={styles.headingIcon} />
                    </div>
                </div>
                <div className={styles.titleInputWrap}>
                    <input
                        className={styles.titleInput}
                        placeholder="Title"
                        onChange={(e) => setMsgTitle(e.target.value)}
                        value={msgTitle}
                    />
                </div>
            </>
        )
    }

    const renderAttachment = () => {
        if (attachedFile) {
            const isImage = attachedFile.mimetype.includes("image")
            const isVideo = attachedFile.mimetype.includes("video")
            const isFile = !isImage && !isVideo
            return (
                <div className={styles.attachmentContainer}>
                    {isImage && <img src={attachedFile.url} className={styles.previewMedia} alt="" />}
                    {isVideo && <video autoPlay={false} className={styles.previewMedia} src={attachedFile.url} />}
                    {isFile && (
                        <>
                            <FileIcon className={styles.fileIcon} width={24} height={24} />
                            <div className={styles.attachmentFileName}>{attachedFile.filename}</div>
                        </>
                    )}
                    <Tooltip title="Remove attachment">
                        <CloseIcon
                            className={isFile ? styles.removeIcon : styles.removePreview}
                            width={24}
                            height={24}
                            onClick={() => setAttachedFile(undefined)}
                        />
                    </Tooltip>
                </div>
            )
        }

        if (mediaLibraryItem) {
            return (
                <div className={styles.attachmentContainer}>
                    <Icon icon={MEDIA_LIBRARY_ICONS[mediaLibraryItem.type]} className={styles.mediaLibraryIcon} />
                    <div className={styles.attachmentFileName}>{mediaLibraryItem.title}</div>
                    <Tooltip title="Remove media library">
                        <CloseIcon
                            className={styles.removeIcon}
                            width={24}
                            height={24}
                            onClick={() => setMediaLibraryItem(undefined)}
                        />
                    </Tooltip>
                </div>
            )
        }

        return null
    }

    const renderReplyMessage = () => {
        if (replyMessage && replyMessage.messageId) {
            return (
                <div className={styles.replyWrap}>
                    <ReplyMessage message={replyMessage} containerStyle={styles.replyContainer} />
                    <div className={cx(styles.option, styles.cancelReply)} onClick={cancelReply}>
                        <CloseIcon className={styles.cancelReplyIcon} />
                    </div>
                </div>
            )
        }
    }

    return (
        <div className={styles.root}>
            {renderChannelView()}
            {isNewSupportRequest && renderNewSupportView()}
            {renderReplyMessage()}
            <TemplateSelection
                withEmail={sendWithEmail}
                withSMS={sendWithSms}
                setValue={setMsgText}
                selectedTemplate={selectedTemplate}
                setSelectedTemplate={setSelectedTemplate}
                descendants={descendants}
                setDescendants={setDescendants}
                setSubject={setSubject}
                subject={subject}
                selectedUserId={selectedUserId}
                setLoading={setIsFetchingTemplate}
                serializeWithoutUser={[
                    Communication.RoomTypes.DirectMessage,
                    Communication.RoomTypes.MultiDepartmental
                ].includes(activeRoom.type)}
            />
            {!sendWithEmail && !sendWithSms ? (
                <>
                    <MentionsInput
                        placeholder={isNewSupportRequest ? "Description" : "Type message"}
                        value={msgText}
                        onKeyDown={onPressEnterSend}
                        onChange={onChangeText}
                        className={"mentions"}
                        classNames={inputStyles}>
                        <Mention
                            trigger="@"
                            markup="[@__display__:__id__]"
                            displayTransform={(_, display) => {
                                return `@${display}`
                            }}
                            style={{
                                backgroundColor: "#cee4e5"
                            }}
                            data={userList}
                            renderSuggestion={(suggestion, search, highlightedDisplay, index, focused) => (
                                <div className={styles.mentionsListItem}>
                                    <div className={styles.mentionsListImageWrapper}>
                                        {["0", "00"].includes(suggestion.id) ? (
                                            <Icon icon="VOL" />
                                        ) : (
                                            <UserAvatar user={suggestion} />
                                        )}
                                    </div>
                                    {highlightedDisplay}{" "}
                                    <em className={styles.descriptionMention}>{suggestion.description}</em>
                                </div>
                            )}
                        />
                    </MentionsInput>
                </>
            ) : (
                <RichTextEditor
                    value={descendants}
                    onChange={setDescendants}
                    userDateFormat={userDateFormat}
                    userTimeFormat={userTimeFormat}
                    dataFieldInsertable={true}
                    inputFieldInsertable={false}
                    wrapperClassName={styles.messageRichTextEditor}
                />
            )}
            {renderAttachment()}
            <div className={styles.actionWrap}>
                {!sendWithEmail && !sendWithSms ? (
                    <div className={styles.optionWrap}>
                        <Tooltip title="Add emoji">
                            <div className={styles.option} onClick={emojiPopup.open}>
                                <EmojiiIcon className={styles.icon} />
                                <EmojiPicker
                                    isShow={emojiPopup.isVisible}
                                    onClose={emojiPopup.close}
                                    onSelect={onSelectEmoji}
                                    className={cx(styles.emojiPicker, emojiPickerClassName)}
                                />
                            </div>
                        </Tooltip>
                        {!isEditting && (
                            <>
                                {isPollEnabled && (
                                    <>
                                        <div className={styles.divider} />
                                        <Tooltip title="Create a Poll">
                                            <div className={styles.option} onClick={pollPopup.open}>
                                                <Icon icon="BAR_CHART" color="#1E90FF" className={styles.icon} />
                                            </div>
                                        </Tooltip>
                                    </>
                                )}
                                <div className={styles.divider} />
                                <Tooltip title="Send a file">
                                    <div className={styles.option} onClick={() => attachFileRef.current.click()}>
                                        <AttachmentIcon className={styles.icon} />
                                        <input
                                            style={{display: "none"}}
                                            id="send-file"
                                            ref={attachFileRef}
                                            type="file"
                                            onChange={onSelectFile}
                                            onClick={onClickFile}
                                            accept="image/*,video/*,.pdf"
                                        />
                                    </div>
                                </Tooltip>
                                {!isNewSupportRequest && !isSupportRoom && (
                                    <>
                                        <div className={styles.divider} />
                                        <Tooltip title="Attach from media library">
                                            <div className={styles.option} onClick={libraryPopup.open}>
                                                <FolderIcon className={styles.icon} />
                                            </div>
                                        </Tooltip>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                ) : (
                    <div className={styles.optionWrap} />
                )}
                <div className={styles.actionRightWrap}>
                    {isScheduledRoom && (
                        <div className={styles.scheduledSettingWrap}>
                            <span className={styles.scheduledText}>Post on</span>
                            <BaseDatePicker
                                placeholder="Date"
                                disabledDate={(currentDate) => currentDate < moment().startOf("day")}
                                value={scheduledDate}
                                onChange={setScheduledDate}
                            />
                            <BaseTimePicker
                                placeholder="Time"
                                format="HH:mm"
                                value={scheduledTime}
                                onChange={setScheduledTime}
                            />
                        </div>
                    )}
                    <BaseButton
                        type="primary"
                        title={isEditting ? "Save" : isChannelOrScheduledRoom ? "Post" : "Send"}
                        size="small"
                        disabled={!isSendAvailable}
                        loading={isSending}
                        onClick={onClickSend}
                    />
                </div>
            </div>
            {showSendOptions && !isEditting && (
                <div className={styles.actionWrap}>
                    <div className={styles.optionWrap}>
                        <div
                            className={cx(styles.checkWrap, {[styles.checkedWrap]: sendWithEmail})}
                            onClick={onToggleSendAsEmail}>
                            <Checkbox checked={sendWithEmail} />
                            <span className={styles.checkTitle}>Send As Email</span>
                        </div>
                        {isDepartmentRoom && isStaffOrAdmin && (
                            <div
                                className={cx(styles.checkWrap, {[styles.checkedWrap]: withStaffsNotification})}
                                onClick={() => setWithStaffsNotification(!withStaffsNotification)}>
                                <Checkbox checked={withStaffsNotification} />
                                <span className={styles.checkTitle}>Include notification to rest of staffs</span>
                            </div>
                        )}
                        {canSendSms && (
                            <div
                                className={cx(styles.checkWrap, {[styles.checkedWrap]: sendWithSms})}
                                onClick={onToggleSendAsSms}>
                                <Checkbox checked={sendWithSms} />
                                <span className={styles.checkTitle}>Send As SMS</span>
                            </div>
                        )}
                        {canCreateActivity && (
                            <div
                                className={cx(styles.checkWrap, {[styles.checkedWrap]: withActivity})}
                                onClick={() => setWithActivity(!withActivity)}>
                                <Checkbox checked={withActivity} />
                                <span className={styles.checkTitle}>Save to activity</span>
                            </div>
                        )}
                    </div>
                </div>
            )}
            <PollPopupV2 isShow={pollPopup.isVisible} onClosePopup={pollPopup.close} roomId={pollRoomId} />
            <MediaLibraryPopup
                isShow={libraryPopup.isVisible}
                onClose={libraryPopup.close}
                onSelect={onSelectMediaLibraryItem}
            />
            {(isSending || isFetchingTemplate) && <BaseLoading isShow={isSending || isFetchingTemplate} />}
        </div>
    )
}
