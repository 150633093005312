import React from "react"
import * as QueryString from "query-string"
import {TFunction, withTranslation} from "react-i18next"
import {isEmpty, keyBy} from "lodash"
import {ConfirmPopup} from "uiKit"
import {store, getQueryStringFromSearchParams} from "helpers"
import {PaginationPerPage} from "types/common"
import {useModel} from "hooks"
import {Model} from "Model"
import {FilterKey} from "types/filter"

type State = {
    isClassComponent: boolean
    isLoading: boolean
    data: any[]
    page: number
    total: number
    pageSize: number
    fields: any[]
    allFields: any[]
    columns: any[]
    menuActions: any[]
    tableHeaderActions: any[]
    orderField: any
    isModalVisible: boolean
    isHideMenuActions: boolean
    isShowTableHeaderAction: boolean
    modalAction: string
    pageTitle: string
    locale: string
    keyRender: string
    filterMemoryKey: FilterKey
    isLoadedTableFuncs: boolean
}

const withModel = (Component) => {
    return (props) => {
        const model = useModel()
        return <Component {...props} model={model} />
    }
}

export const KlassappTableHOC = <T,>(
    WrappedComponent: React.ComponentType<T>,
    defaultProps?: Partial<{page: number; pageSize: number}>
) => {
    type KlassappTableHOCProps = {
        t: TFunction
        history?: any
        model?: Model
    }
    type Props = T & KlassappTableHOCProps

    class Wrapper extends React.Component<Props, State> {
        constructor(props) {
            super(props)
            const locale = store.get("locale") || "en"
            const {page, pageSize} = this.getPageInfo()
            this.state = {
                isClassComponent: true,
                isLoading: false,
                data: [],
                page,
                total: 1,
                pageSize,
                fields: [],
                allFields: [],
                columns: [],
                menuActions: [],
                tableHeaderActions: [],
                orderField: {},
                isModalVisible: false,
                isHideMenuActions: false,
                isShowTableHeaderAction: false,
                modalAction: "",
                pageTitle: "",
                keyRender: "id",
                filterMemoryKey: null,
                isLoadedTableFuncs: false,
                locale
            }
        }

        getPageInfo = () => {
            let page = defaultProps?.page || 1
            let pageSize = defaultProps?.pageSize || PaginationPerPage.item20
            if (this.props.history) {
                const searchParams: any = QueryString.parse(this.props.history.location.search)
                page = searchParams.page ? +searchParams.page : page
                pageSize = searchParams.pageSize ? +searchParams.pageSize : pageSize
            }
            return {page, pageSize}
        }

        componentDidMount() {
            setTimeout(() => {
                this.initFunction()
            }, 0)
        }

        componentDidUpdate() {
            const {locale} = this.state
            if (locale !== store.get("locale")) {
                this.initFunction()
            }
        }

        initFunction() {
            const pageTitle = this.getPageTitle()
            const keyRender = this.getKeyRender()
            let columns = this.getColumns()
            let fields = this.getFields()
            let allFields = columns.map((column) => column.title)
            const menuActions = this.getMenuActions()
            const tableHeaderActions = this.getTableHeaderActions()
            const filterMemoryKey = this.getFilterMemoryKey()
            const locale = store.get("locale")
            let {page, pageSize, orderField} = this.state
            let newState: any = {}
            if (filterMemoryKey) {
                const storageData = this.props.model.getStorageFilter(filterMemoryKey)
                if (storageData?.page) {
                    page = storageData.page
                }
                if (storageData?.pageSize) {
                    pageSize = storageData.pageSize
                }
                if (storageData?.colsIdx || storageData?.colsAct) {
                    columns = columns.map((column, index) => ({
                        ...column,
                        columnIndex: column.columnIndex || index + 1
                    }))
                }
                const columnsByIdx = keyBy(columns, "columnIndex")
                if (storageData?.colsAct) {
                    const activeColumns = storageData.colsAct.map((colIdx) => columnsByIdx[colIdx])
                    fields = activeColumns.filter((column) => !isEmpty(column)).map((column) => column.title)
                }
                if (storageData?.colsIdx) {
                    columns = storageData.colsIdx.map((colIdx) => columnsByIdx[colIdx])
                    allFields = columns.filter((column) => !isEmpty(column)).map((column) => column.title)
                }
                if (storageData?.colOrd) {
                    const orderColumn = columnsByIdx[storageData.colOrd.idx]
                    if (orderColumn && !orderField?.field) {
                        newState.orderField = {
                            field: orderColumn.orderField || orderColumn.field,
                            order: storageData.colOrd.order
                        }
                    }
                }
            }
            newState = {
                ...newState,
                pageTitle,
                fields,
                allFields,
                columns,
                menuActions,
                tableHeaderActions,
                locale,
                keyRender,
                page,
                pageSize,
                isLoadedTableFuncs: true,
                filterMemoryKey
            }
            this.setState(newState)
        }

        dispatch = (newState, callback = () => {}) => {
            this.setState({...newState}, () => {
                if (callback) {
                    callback()
                }
            })
        }

        dispatchFunc = (functions) => {
            functions.forEach(({key, func}) => {
                this[key] = func
            })
            this.initFunction()
        }

        getCurrentData = () => {
            return this.state.data
        }

        getCurrentPage = () => {
            return this.state.page
        }

        getCurrentPageSize = () => {
            return this.state.pageSize
        }

        getPageTitle = () => {
            return ""
        }

        getKeyRender = () => {
            return "id"
        }

        getFilterMemoryKey = () => {
            return null
        }

        getListData = () => {}

        getFields = () => {
            return []
        }

        getColumns = () => {
            return []
        }

        onClickEdit = () => {}

        onClickDelete = () => {}

        getMenuActions = () => {
            const {t} = this.props
            return [
                {
                    title: t("common:action.edit"),
                    icon: "EDIT",
                    action: this.onClickEdit
                },
                {
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: this.onClickDelete
                }
            ]
        }

        onClickShowConfirmModal = (modalAction) => {
            this.dispatch({isModalVisible: true, modalAction})
        }

        getTableHeaderActions = (isShowDuplicateBtn = true, checkedData = []) => {
            const {t} = this.props
            const actions = [
                {
                    title: t("common:action.delete"),
                    icon: "DELETE",
                    action: () => this.onClickShowConfirmModal("DELETE")
                }
            ]
            if (isShowDuplicateBtn) {
                actions.push({
                    title: t("common:action.duplicate"),
                    icon: "DUPLICATE",
                    action: () => this.onClickDuplicateMulti()
                })
            }
            return actions
        }

        updateTableHeaderActions = () => {
            const {isLoading, data} = this.state
            const checkedData = data.filter((item) => item.isChecked)
            const isShowDuplicateBtn = checkedData.length === 1
            const isHideMenuActions = checkedData.length > 1
            const isShowTableHeaderAction = !isLoading && checkedData.length >= 1
            const tableHeaderActions = this.getTableHeaderActions(isShowDuplicateBtn, checkedData)
            this.dispatch({tableHeaderActions, isHideMenuActions, isShowTableHeaderAction})
        }

        onUpdateRowData = (updatedData) => {
            const {data, keyRender} = this.state
            const newData = data.map((item) => {
                if (item[keyRender] === updatedData[keyRender]) {
                    return updatedData
                }
                return {...item, isActiveTableCol: false}
            })
            this.dispatch({data: newData}, () => {
                this.updateTableHeaderActions()
            })
        }

        onUpdateTableData = (data) => {
            this.dispatch({data}, () => {
                this.updateTableHeaderActions()
            })
        }

        onClickRowItem = (data) => {
            this.onUpdateRowData({...data, isActiveTableCol: !data.isActiveTableCol})
        }

        onDoubleClickRowItem = (data) => {}

        onClickSortColumn = (field) => {
            const {isClassComponent, filterMemoryKey} = this.state
            const {model} = this.props
            let orderField = null
            if (isEmpty(this.state.orderField)) {
                orderField = {field, order: "asc"}
            } else {
                orderField = {field, order: this.state.orderField.order === "asc" ? "desc" : "asc"}
            }
            const columns = this.state.columns.map((column, index) => ({
                ...column,
                columnIndex: column.columnIndex || index + 1
            }))
            const colOrd = columns.find((column) => column.orderField === field || column.field === field)
            model.updateStorageFilter(filterMemoryKey, {colOrd: {idx: colOrd.columnIndex, order: orderField.order}})
            this.dispatch({orderField}, async () => {
                if (isClassComponent) {
                    this.setState({isShowTableHeaderAction: false, isHideMenuActions: false})
                    await this.getListData()
                }
            })
        }

        onChangePage = (newPage) => {
            const {isClassComponent, filterMemoryKey} = this.state
            const {history, model} = this.props
            if (history) {
                const {location} = history
                const searchParams = QueryString.parse(location.search)
                searchParams.page = newPage
                location.search = getQueryStringFromSearchParams(searchParams)
                history.replace({...location})
            }
            model.updateStorageFilter(filterMemoryKey, {page: newPage})
            this.dispatch({page: newPage}, () => {
                if (isClassComponent) {
                    this.setState({isShowTableHeaderAction: false, isHideMenuActions: false})
                    this.getListData()
                }
            })
        }

        onChangeRowPerPage = (pageSize) => {
            const {isClassComponent, filterMemoryKey} = this.state
            const {history, model} = this.props
            if (history) {
                const {location} = history
                const searchParams = QueryString.parse(location.search)
                searchParams.page = "1"
                searchParams.pageSize = pageSize
                location.search = getQueryStringFromSearchParams(searchParams)
                history.replace({...location})
            }
            model.updateStorageFilter(filterMemoryKey, {pageSize, page: 1})
            this.dispatch({page: 1, pageSize}, () => {
                if (isClassComponent) {
                    this.setState({isShowTableHeaderAction: false, isHideMenuActions: false})
                    this.getListData()
                }
            })
        }

        onChangeFields = (fields) => {
            const {filterMemoryKey} = this.state
            const {model} = this.props
            const columns = this.state.columns.map((column, index) => ({
                ...column,
                columnIndex: column.columnIndex || index + 1
            }))
            const columnsByTitle = keyBy(columns, "title")
            const colsAct = fields.map((title) => columnsByTitle[title]).map((column) => column.columnIndex)
            model.updateStorageFilter(filterMemoryKey, {colsAct})
            this.dispatch({fields})
        }

        onChangeAllFields = (fields) => {
            this.dispatch({allFields: fields})
        }

        onModalCancel = () => {
            this.dispatch({isModalVisible: false})
        }

        onClickDeleteMulti = () => {}

        onClickDuplicateMulti = () => {}

        onModalAccept = () => {
            const {modalAction} = this.state
            switch (modalAction) {
                case "DELETE":
                case "DEACTIVATE":
                    this.onClickDeleteMulti()
                    break
                case "DUPLICATE":
                    this.onClickDuplicateMulti()
                    break
                default:
                    break
            }
            this.dispatch({isModalVisible: false})
        }

        reorder = (list, startIndex: number, endIndex: number) => {
            const result = Array.from(list)
            const removedItems: any = result.splice(startIndex, 1)
            const [removed] = removedItems
            result.splice(endIndex, 0, removed)
            return result
        }

        onDraggableColumn = (sourceIndex: number, destIndex: number) => {
            const {columns, filterMemoryKey} = this.state
            const {model} = this.props
            const newColumns = this.reorder(columns, sourceIndex, destIndex)
            const colsIdx = newColumns.map((column: any, index) => column.columnIndex || index + 1)
            model.updateStorageFilter(filterMemoryKey, {colsIdx})
            this.dispatch({columns: newColumns})
        }

        render() {
            const {
                pageTitle,
                isLoading,
                data,
                page,
                total,
                pageSize,
                fields,
                allFields,
                columns,
                menuActions,
                tableHeaderActions,
                orderField,
                isModalVisible,
                modalAction,
                isHideMenuActions,
                isLoadedTableFuncs,
                isShowTableHeaderAction
            } = this.state
            const {t} = this.props
            const modalTitle = (pageTitle || "").endsWith("s")
                ? t(`common:confirmationPopupEndWithS.${modalAction.toLowerCase()}`, {title: pageTitle})
                : t(`common:confirmationPopup.${modalAction.toLowerCase()}`, {title: pageTitle})

            return (
                <>
                    <WrappedComponent
                        {...this.props}
                        isLoading={isLoading}
                        data={data}
                        page={page}
                        total={total}
                        pageSize={pageSize}
                        fields={fields}
                        allFields={allFields}
                        columns={columns}
                        menuActions={menuActions}
                        tableHeaderActions={tableHeaderActions}
                        orderField={orderField}
                        isHideMenuActions={isHideMenuActions}
                        isShowTableHeaderAction={isShowTableHeaderAction && !isLoading}
                        isLoadedTableFuncs={isLoadedTableFuncs}
                        dispatch={this.dispatch}
                        dispatchFunc={this.dispatchFunc}
                        onClickSortColumn={this.onClickSortColumn}
                        onUpdateRowData={this.onUpdateRowData}
                        onUpdateTableData={this.onUpdateTableData}
                        onChangeFields={this.onChangeFields}
                        onChangeAllFields={this.onChangeAllFields}
                        onChangePage={this.onChangePage}
                        onChangeRowPerPage={this.onChangeRowPerPage}
                        onClickRowItem={this.onClickRowItem}
                        onDoubleClickRowItem={this.onDoubleClickRowItem}
                        updateTableHeaderActions={this.updateTableHeaderActions}
                        onClickShowConfirmModal={this.onClickShowConfirmModal}
                        getCurrentData={this.getCurrentData}
                        getCurrentPage={this.getCurrentPage}
                        getCurrentPageSize={this.getCurrentPageSize}
                        onDraggableColumn={this.onDraggableColumn}
                    />
                    <ConfirmPopup
                        isVisible={isModalVisible}
                        title={modalTitle}
                        onClose={this.onModalCancel}
                        onConfirm={this.onModalAccept}
                    />
                </>
            )
        }
    }

    return withTranslation(["common"])(withModel(Wrapper))
}
