/* eslint-disable react-hooks/exhaustive-deps */
import React, {useCallback, useEffect, useState} from "react"
import {KlassappTable, KlassappTableHeader} from "uiKit"
import {KlassappTableHOC} from "HOC"
import {useModel, useVisible} from "hooks"
import styles from "./LedgerTab.module.css"
import {PermissionsRequired} from "components/PermissionsRequired"
import {Permissions} from "types/permission"
import {Checkbox, Col, Popover, Row} from "antd"
import {KlassappTableProps, Order} from "types/common"
import cx from "classnames"
import {useTranslation} from "react-i18next"
import {formatCurrency, getFullName, handleError, toastError} from "helpers"
import {Icon} from "components"
import {studentLedgerService, studentService} from "services"
import {useParams} from "react-router-dom"
import {StudentStats, StudentTransaction, StudentTransactionType} from "types/student-account/student-ledger"
import moment from "moment/moment"
import {UNLIMITED_PAGE_SIZE} from "data/constants"
import {ExportButton, ExportFileType} from "components/ui"
import {get, isEmpty, uniqBy} from "lodash"
import {exportCsv, exportExcel} from "helpers/export-table"
import {LedgerCardPopup} from "sections/StudentAccount/Student/parts/StudentLedgerTab/parts/LedgerTab/LedgerCardPopup"
import {Auth} from "types/auth"
import {BaseDepartmentId} from "types/departments"
import {toDateOnly} from "sections/academics/instructional/common/utils"
import {FilterKey} from "types/filter"
import {PAYMENT_METHOD_OPTIONS} from "types/student-account/student-payment"
import {LedgerAccount} from "types/student-account/ledger-accounts"
import {useAllLedgerAccounts} from "hooks/useAllLedgerAccounts"

const currentYear = moment().year()
const yearOptions = [-3, -2, -1, 0].map((index) => ({
    value: currentYear + index,
    label: `${currentYear + index} - ${currentYear + index + 1}`
}))

const LedgerTab = () => {
    const params = useParams<{profileId: string}>()
    const profileId = +params.profileId
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const [student, setStudent] = React.useState<Auth.DepartmentStudent | undefined>()
    const [stats, setStats] = useState<StudentStats>({acadYearStats: {}, awardYearStats: {}})
    const [includesProjected, setIncludesProjected] = useState(true)
    const [isGroupedByAcadYear, setGroupedByAcadYear] = useState(false)
    const [isGroupedByAwardYear, setGroupedByAwardYear] = useState(false)

    useEffect(() => {
        ;(async function getStats() {
            setStats(await studentLedgerService.getStudentStats(profileId))
        })()
        ;(async function loadStudent() {
            try {
                const {
                    data: [student]
                } = await studentService.getDepartmentStudents({
                    filter: {profileIds: [profileId], departmentId: BaseDepartmentId.StudentAccount}
                })
                setStudent(student)
            } catch (error) {
                toastError("Student not found")
            }
        })()
    }, [profileId])

    return (
        <div>
            <div className={styles.statsContainer}>
                <div className={styles.statsBlock}>
                    <h5 className={styles.statsTitle}>Account Balance</h5>
                    <p className={styles.statsSubtitle}>As of {moment().format(userDateFormat)}</p>
                    <p className={styles.statsValue}>{formatCurrency(stats.acadYearStats[0]?.balance)}</p>
                </div>

                <div className={styles.statsBlock}>
                    <h5 className={styles.statsTitle}>Payments due/past</h5>
                    <p className={cx(styles.statsValue, styles.due)}>
                        <span className={cx(styles.statsLabel, styles.due)}>Due</span>
                        <span>{stats.paymentDueCount ?? 0}</span>
                        <br />
                        <span className={cx(styles.statsLabel, styles.pastDue)}>Past due</span>
                        <span>{stats.paymentPastDueCount ?? 0}</span>
                    </p>
                </div>

                <div className={styles.statsBlock}>
                    <h5 className={styles.statsTitle}>Next payment</h5>
                    <p className={styles.statsValue}>{formatCurrency(stats.nextPaymentAmount)}</p>
                    {!!stats.nextPaymentDate && (
                        <p className={styles.statsDate}>
                            {moment(toDateOnly(stats.nextPaymentDate)).format(userDateFormat)}
                        </p>
                    )}
                </div>

                <div className={styles.statsBlock}>
                    <h5 className={styles.statsTitle}>Past due</h5>
                    <p className={styles.statsValue}>{formatCurrency(stats.paymentPastDueAmount)}</p>
                    {!!stats.paymentPastDueDate && (
                        <p className={styles.statsDate}>
                            {moment(toDateOnly(stats.paymentPastDueDate)).format(userDateFormat)}
                        </p>
                    )}
                </div>

                <div className={styles.statsBlock}>
                    <h5 className={styles.statsTitle}>Next proj disbursement</h5>
                    <p className={styles.statsValue}>{formatCurrency(stats.nextProjectedDisbursementAmount)}</p>
                    {!!stats.nextProjectedDisbursementDate && (
                        <p className={styles.statsDate}>
                            {moment(toDateOnly(stats.nextProjectedDisbursementDate)).format(userDateFormat)}
                        </p>
                    )}
                </div>
            </div>

            <Row gutter={24} justify="end" align="middle" className="mt-8 mb-24">
                <Col>
                    <div
                        className={cx(styles.checkbox, {
                            [styles.active]: isGroupedByAwardYear
                        })}>
                        <Checkbox
                            checked={isGroupedByAwardYear}
                            onChange={(event) => {
                                setGroupedByAwardYear(event.target.checked)
                                setGroupedByAcadYear(false)
                            }}>
                            View by FA Award Year
                        </Checkbox>
                    </div>
                </Col>
                <Col>
                    <div
                        className={cx(styles.checkbox, {
                            [styles.active]: isGroupedByAcadYear
                        })}>
                        <Checkbox
                            checked={isGroupedByAcadYear}
                            onChange={(event) => {
                                setGroupedByAcadYear(event.target.checked)
                                setGroupedByAwardYear(false)
                            }}>
                            View by Academic Year
                        </Checkbox>
                    </div>
                </Col>
                <Col>
                    <div
                        className={cx(styles.checkbox, {
                            [styles.active]: includesProjected
                        })}>
                        <Checkbox
                            checked={includesProjected}
                            onChange={(event) => setIncludesProjected(event.target.checked)}>
                            Include Projected
                        </Checkbox>
                    </div>
                </Col>
            </Row>

            {isGroupedByAcadYear ? (
                Object.keys(stats.acadYearStats)
                    .filter((year) => !!+year)
                    .map((year) => (
                        <StudentTransactionsView key={year} acadYear={+year} profileId={profileId} student={student} />
                    ))
            ) : isGroupedByAwardYear ? (
                Object.keys(stats.awardYearStats)
                    .filter((year) => !!+year)
                    .map((year) => (
                        <StudentTransactionsView key={year} awardYear={+year} profileId={profileId} student={student} />
                    ))
            ) : (
                <StudentTransactionsView profileId={profileId} student={student} />
            )}
        </div>
    )
}

export default LedgerTab

type StudentTransactionsTableProps = KlassappTableProps & {
    profileId: number
    student?: Auth.DepartmentStudent
    acadYear?: number
    awardYear?: number
}

const StudentTransactionsTableView: React.FC<StudentTransactionsTableProps> = ({
    dispatch,
    dispatchFunc,
    page,
    total,
    pageSize,
    columns,
    data,
    allFields,
    fields,
    tableHeaderActions,
    updateTableHeaderActions,
    orderField,
    isLoading,
    menuActions,
    isHideMenuActions,
    isShowTableHeaderAction,
    getCurrentData,
    getCurrentPage,
    getCurrentPageSize,
    onChangePage,
    onChangeRowPerPage,
    onChangeFields,
    onUpdateRowData,
    onUpdateTableData,
    onDraggableColumn,
    onChangeAllFields,
    profileId,
    student,
    acadYear,
    awardYear
}) => {
    const {t} = useTranslation(["studentAccount", "common"])
    const model = useModel()
    const userDateFormat = model.getUserDateFormat()
    const filterKey = FilterKey.StudentAccountStudentLedger
    const storageData = model.getStorageFilter(filterKey)
    const {ledgerAccounts} = useAllLedgerAccounts()

    // region filter

    const initialFilter = {
        academicYear: undefined,
        awardYear: undefined
    }
    const [search, setSearch] = useState(!isEmpty(storageData) ? storageData.search : "")
    const [filter, setFilter] = useState(!isEmpty(storageData?.filter) ? storageData.filter : initialFilter)

    const onChangeFilter = (key: keyof typeof filter, value: any) => {
        setFilter((prev) => ({...prev, [key]: value}))
    }

    const onClearFilter = () => {
        setFilter(initialFilter)
    }

    const onApplyFilter = () => {
        getData()
        model.updateStorageFilter(filterKey, {filter, page: 1})
    }

    const onSearchInput = React.useCallback((search = "") => {
        setSearch(search)
        model.updateStorageFilter(filterKey, {search, page: 1})
    }, [])

    useEffect(() => {
        getData()
    }, [search])

    // endregion filter

    const getFields = useCallback(() => {
        return [
            t("ledger.column.date"),
            t("ledger.column.id"),
            t("ledger.column.description"),
            t("ledger.column.transactionType"),
            t("ledger.column.charges"),
            t("ledger.column.payments"),
            t("ledger.column.balance"),
            t("ledger.column.reconcile"),
            t("ledger.column.academicYear"),
            t("ledger.column.awardYear"),
            t("ledger.column.notes")
        ]
    }, [])

    const getColumns = useCallback(() => {
        return [
            {
                title: t("ledger.column.date"),
                field: "tranxDate",
                columnIndex: 1,
                fieldType: "date",
                format: userDateFormat
                // style: { color: 'var(--success-400-base)' },
                // style: { color: "var(--error-400-base)" }
            },
            {
                title: t("ledger.column.id"),
                field: "tranxId",
                columnIndex: 2
            },
            {
                title: t("ledger.column.description"),
                field: "description",
                columnIndex: 3,
                render: (description: string | undefined, record: StudentTransaction) => {
                    switch (record.type) {
                        case StudentTransactionType.Charge:
                            return (
                                record.charge?.description ||
                                (record.charge?.paymentPlan ? `Payment Plan ${record.charge?.paymentPlan?.code}` : "")
                            )
                        case StudentTransactionType.Payment:
                            return record.payment?.extraDescription
                        default:
                            return description
                    }
                },
                renderText: (description: string | undefined, record: StudentTransaction) => {
                    switch (record.type) {
                        case StudentTransactionType.Charge:
                            return (
                                record.charge?.description ||
                                (record.charge?.paymentPlan ? `Payment Plan ${record.charge?.paymentPlan?.code}` : "")
                            )
                        case StudentTransactionType.Payment:
                            return record.payment?.extraDescription || ""
                        default:
                            return description || ""
                    }
                }
            },
            {
                title: t("ledger.column.transactionType"),
                field: "tranxId",
                columnIndex: 4,
                render: (_, record: StudentTransaction) => {
                    switch (record.type) {
                        case StudentTransactionType.Charge: {
                            return record.charge?.account?.name || record.charge?.customAccountName || ""
                        }
                        case StudentTransactionType.Payment: {
                            const {account, paymentCharges = [], paymentPlanItems = []} = record.payment ?? {}
                            const associatedAccounts: LedgerAccount[] = [account]
                            for (const paymentCharge of paymentCharges) {
                                associatedAccounts.push(
                                    ledgerAccounts.find((account) => account.accountId === paymentCharge.accountId)
                                )
                            }
                            for (const paymentPlanItem of paymentPlanItems) {
                                associatedAccounts.push(
                                    ledgerAccounts.find((account) => account.accountId === paymentPlanItem.accountId)
                                )
                            }
                            return uniqBy(associatedAccounts.filter(Boolean), "accountId")
                                .map((account) => account.name)
                                .join(", ")
                        }
                        default:
                            return ""
                    }
                }
            },
            {
                title: t("ledger.column.charges"),
                field: "chargeTotal",
                columnIndex: 5,
                headerStyle: {textAlign: "right"},
                style: {textAlign: "right"},
                render: (chargeTotal: number | undefined, record: StudentTransaction) => {
                    if (chargeTotal != null) {
                        return formatCurrency(chargeTotal)
                    }
                    if (record.type === StudentTransactionType.Charge) {
                        return formatCurrency(record.amount, "usd", {currencyDisplay: "code"})
                            .replace(/[a-z]{3}/i, "")
                            .trim()
                    }
                    return ""
                },
                renderText: (chargeTotal: number | undefined, record: StudentTransaction) => {
                    if (chargeTotal != null) {
                        return chargeTotal.toFixed(2)
                    }
                    if (record.type === StudentTransactionType.Charge) {
                        return (record.amount ?? 0).toFixed(2)
                    }
                    return ""
                }
            },
            {
                title: t("ledger.column.payments"),
                field: "paymentTotal",
                columnIndex: 6,
                headerStyle: {textAlign: "right"},
                style: {textAlign: "right"},
                render: (paymentTotal: number | undefined, record: StudentTransaction) => {
                    if (paymentTotal != null) {
                        return formatCurrency(paymentTotal)
                    }
                    if (record.type === StudentTransactionType.Payment) {
                        return formatCurrency(record.amount, "usd", {currencyDisplay: "code"})
                            .replace(/[a-z]{3}/i, "")
                            .trim()
                    }
                    return ""
                },
                renderText: (paymentTotal: number | undefined, record: StudentTransaction) => {
                    if (paymentTotal != null) {
                        return paymentTotal.toFixed(2)
                    }
                    if (record.type === StudentTransactionType.Payment) {
                        return (record.amount ?? 0).toFixed(2)
                    }
                    return ""
                }
            },
            {
                title: t("ledger.column.balance"),
                field: "latestBalance",
                columnIndex: 7,
                headerStyle: {textAlign: "right"},
                style: {textAlign: "right"},
                render: (latestBalance: number | undefined, record: StudentTransaction) => {
                    if (latestBalance != null) {
                        return formatCurrency(latestBalance)
                    }
                    return (
                        <span className="whitespace-nowrap">
                            {formatCurrency(record.balance, "usd", {currencyDisplay: "narrowSymbol"})
                                .replace(/[a-z]{3}/i, "")
                                .trim()}
                        </span>
                    )
                },
                renderText: (latestBalance: number | undefined, record: StudentTransaction) => {
                    if (latestBalance != null) {
                        return latestBalance.toFixed(2)
                    }
                    return (record.balance ?? 0).toFixed(2)
                }
            },
            {
                title: t("ledger.column.reconcile"),
                field: "reconcileDate",
                columnIndex: 8,
                render: (reconcileDate: string | undefined, record: StudentTransaction) => {
                    if (reconcileDate) {
                        return (
                            <div className={styles.completedDateWrap}>
                                <div>
                                    <Icon icon="CHECKMARK_CIRCLE" color="#18A957" />
                                </div>
                                <div className={styles.completedDateInfo}>
                                    <p className={styles.completedDateInfo__item}>
                                        {getFullName(record.reconcileByProfile)}
                                    </p>
                                    <p className={styles.completedDateInfo__item}>
                                        {moment(toDateOnly(reconcileDate)).format(userDateFormat)}
                                    </p>
                                </div>
                            </div>
                        )
                    }
                    return null
                },
                renderText: (reconcileDate: string | undefined, record: StudentTransaction): string => {
                    if (reconcileDate) {
                        return moment(toDateOnly(reconcileDate)).format(userDateFormat)
                    }
                    return ""
                }
            },
            {
                title: t("ledger.column.institutionalCharge"),
                field: "charge",
                columnIndex: 9,
                render: (_, record: StudentTransaction) => {
                    switch (record.type) {
                        case StudentTransactionType.Charge:
                            return (
                                !!record.charge?.isInstitutionalCharge && (
                                    <Icon icon="WHITE_INFO" className={styles.checkmarkIcon} />
                                )
                            )
                        case StudentTransactionType.Payment:
                        default:
                            return null
                    }
                },
                renderText: (_, record: StudentTransaction) => {
                    if (record.type === StudentTransactionType.Charge) {
                        return String(!!record.charge?.isInstitutionalCharge)
                    }
                    return ""
                }
            },
            {
                title: t("ledger.column.academicYear"),
                field: "academicYear",
                columnIndex: 10,
                style: {minWidth: 100},
                render: (year) => (year ? `${year} - ${year + 1}` : null)
            },
            {
                title: t("ledger.column.awardYear"),
                field: "awardYear",
                columnIndex: 11,
                style: {minWidth: 100},
                render: (year) => (year ? `${year} - ${year + 1}` : null)
            },
            {
                title: t("ledger.column.methodOfPayment"),
                field: "payment.methodOfPayment",
                columnIndex: 12,
                render: (methodOfPayment?: string) =>
                    PAYMENT_METHOD_OPTIONS.find((option) => option.id === methodOfPayment)?.name ||
                    methodOfPayment ||
                    ""
            },
            {
                title: t("ledger.column.notes"),
                titleHtml: <span />,
                field: "description",
                columnIndex: 13,
                render: (_, record: StudentTransaction) => {
                    switch (record.type) {
                        case StudentTransactionType.Charge: {
                            const notes = record.charge?.notes
                            return (
                                !!notes &&
                                (notes.length < 50 ? (
                                    notes
                                ) : (
                                    <Popover
                                        trigger="click"
                                        title="Notes"
                                        content={notes}
                                        overlayClassName={styles.notes}>
                                        <span onClick={(e) => e.stopPropagation()}>
                                            <Icon icon="MESSAGE_CIRCLE_LINE" />
                                        </span>
                                    </Popover>
                                ))
                            )
                        }
                        case StudentTransactionType.Payment: {
                            const notes = record.payment?.notes
                            return (
                                !!notes &&
                                (notes.length < 50 ? (
                                    notes
                                ) : (
                                    <Popover
                                        trigger="click"
                                        title="Notes"
                                        content={record.payment?.notes}
                                        overlayClassName={styles.notes}>
                                        <span onClick={(e) => e.stopPropagation()}>
                                            <Icon icon="MESSAGE_CIRCLE_LINE" />
                                        </span>
                                    </Popover>
                                ))
                            )
                        }
                        default:
                            return null
                    }
                },
                renderText: (_, record: StudentTransaction) => {
                    switch (record.type) {
                        case StudentTransactionType.Charge:
                            return record.charge?.notes || ""
                        case StudentTransactionType.Payment:
                            return record.payment?.notes || ""
                        default:
                            return ""
                    }
                }
            }
        ]
    }, [ledgerAccounts])

    const getData = React.useCallback(async () => {
        try {
            dispatch({isLoading: true})
            const {total, data} = await studentLedgerService.getTransactions({
                filter: {
                    search,
                    profileIds: [profileId],
                    academicYears: acadYear ? [acadYear] : filter.academicYear ? [filter.academicYear] : undefined,
                    awardYears: awardYear ? [awardYear] : filter.awardYear ? [filter.awardYear] : undefined
                },
                range: {
                    pageSize: UNLIMITED_PAGE_SIZE,
                    page: 1
                },
                sort: {
                    orderBy: "tranxDate",
                    orderDir: Order.Asc
                },
                linkedEntities: true
            })
            let chargeTotal = 0,
                paymentTotal = 0
            data.forEach((transaction, index) => {
                transaction.id = transaction.tranxId
                const prevBalance = data[index - 1]?.balance || 0
                switch (transaction.type) {
                    case StudentTransactionType.Charge:
                        chargeTotal += transaction.amount
                        transaction.balance = prevBalance + transaction.amount
                        break
                    case StudentTransactionType.Payment:
                        paymentTotal += transaction.amount
                        transaction.balance = prevBalance - transaction.amount
                        break
                }
            })
            data.push({
                id: -1,
                description: "TOTAL",
                chargeTotal,
                paymentTotal,
                latestBalance: data[data.length - 1]?.balance,
                rowClassName: cx(styles.row, styles.total)
            } as StudentTransaction)
            dispatch({total, data})
        } catch (error) {
            console.error(error)
        } finally {
            dispatch({isLoading: false})
        }
    }, [acadYear, awardYear, profileId, filter])

    const onClickRowItem = useCallback((item) => {}, [])

    const onClickDelete = useCallback(async (item) => {}, [])

    useEffect(() => {
        dispatch({isClassComponent: false})
        dispatchFunc([
            {key: "getPageTitle", func: () => "Ledger"},
            {key: "getFilterMemoryKey", func: () => filterKey},
            {key: "getListData", func: getData},
            {key: "getFields", func: getFields},
            {key: "getColumns", func: getColumns},
            {key: "onClickDelete", func: onClickDelete},
            {key: "onClickRowItem", func: onClickRowItem}
        ])
    }, [])

    useEffect(() => {
        dispatchFunc([{key: "getColumns", func: getColumns}])
    }, [getColumns])

    useEffect(() => {
        getData()
    }, [page, pageSize, acadYear, awardYear, profileId, filter])

    const ledgerCardPopup = useVisible(false)
    const [isExporting, setExporting] = useState(false)

    const handleExport = useCallback(
        async (type: ExportFileType) => {
            if (type === "pdf") {
                ledgerCardPopup.open()
                return
            }
            try {
                setExporting(true)
                const payload = {
                    filename: `Student Ledger Statement - ${getFullName(student)} - ${moment().format("YYYY-MM-DD")}`,
                    columns: columns.map((col) => col.title),
                    rows: getCurrentData()
                        .filter((item) => item.id >= 0)
                        .map((item) =>
                            columns.map((col) => {
                                if (col.fieldType === "date") {
                                    return get(item, col.field)
                                        ? moment.utc(get(item, col.field)).format(userDateFormat)
                                        : ""
                                }
                                const value = col.renderText
                                    ? col.renderText(get(item, col.field), item)
                                    : col.render
                                    ? col.render(get(item, col.field), item)?.toString?.()
                                    : get(item, col.field)?.toString?.()
                                return (type === "csv" ? value?.replaceAll(/[^a-zA-Z0-9. -]/g, "") : value) || ""
                            })
                        )
                }
                if (type === "csv") {
                    exportCsv(payload)
                } else if (type === "excel") {
                    exportExcel(payload)
                }
            } catch (error) {
                handleError(error)
            } finally {
                setExporting(false)
            }
        },
        // eslint-disable-next-line
        [userDateFormat, columns, student]
    )

    return (
        <div className={styles.wrap}>
            {!!acadYear && (
                <h3 className={styles.headerTitle}>
                    ACADEMIC YEAR {acadYear} - {acadYear + 1}
                </h3>
            )}
            {!!awardYear && (
                <h3 className={styles.headerTitle}>
                    FA AWARD YEAR {awardYear} - {awardYear + 1}
                </h3>
            )}
            {/*
            <BaseNewFilter
                className="mb-24"
                filter={filter}
                onClick={onApplyFilter}
                onClickClear={onClearFilter}
                onSearchInput={onSearchInput}
                renderRightFilter={() => (
                    <PermissionsRequired
                        permissions={{staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View]}}>
                        <div className={styles.actionWrap}>
                            <ExportButton
                                isLoading={isExporting}
                                onSelect={handleExport}
                                label="Ledger Card"
                                availableFileTypes={["csv", "excel", "pdf"]}
                            />
                        </div>
                    </PermissionsRequired>
                )}>
                <Row gutter={[24, 24]}>
                    <Col span={12}>
                        <KlassDropdown
                            isClearable={true}
                            options={yearOptions}
                            labelKey="label"
                            valueKey="value"
                            placeholder={t("common:selectField.placeholder")}
                            value={yearOptions.find((option) => option.value === filter.academicYear)}
                            onChange={(option) => onChangeFilter("academicYear", option?.value)}
                        />
                    </Col>
                </Row>
            </BaseNewFilter>
*/}
            <PermissionsRequired permissions={{staff: [Permissions.Staff.StudentAccount.Students.StudentLedger.View]}}>
                <div className={styles.actionWrap}>
                    <ExportButton
                        isLoading={isExporting}
                        onSelect={handleExport}
                        label="Ledger Card"
                        availableFileTypes={["csv", "excel", "pdf"]}
                    />
                </div>
            </PermissionsRequired>

            <KlassappTableHeader
                page={page}
                total={total}
                defaultPageSize={pageSize}
                onChangePage={onChangePage}
                onChangeRowPerPage={onChangeRowPerPage}
                fields={fields}
                allFields={allFields}
                onChangeFields={onChangeFields}
                onChangeAllFields={onChangeAllFields}
                onDraggableColumn={onDraggableColumn}
            />
            <KlassappTable
                columns={columns}
                fields={fields}
                data={data}
                menuActions={[]}
                isLoading={isLoading}
                allFields={allFields}
                isShowCheckedColumn={false}
                orderField={orderField}
                onClickRowItem={onClickRowItem}
                onChangeFields={onChangeFields}
                onUpdateRowData={onUpdateRowData}
                onUpdateTableData={onUpdateTableData}
                onDraggableColumn={onDraggableColumn}
                onChangeAllFields={onChangeAllFields}
            />

            <LedgerCardPopup
                isShow={ledgerCardPopup.isVisible}
                onClose={ledgerCardPopup.close}
                student={student}
                transactions={data}
            />
        </div>
    )
}

export const StudentTransactionsView = KlassappTableHOC(StudentTransactionsTableView)
